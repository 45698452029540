import {Accordion, Button, Card, CardDeck, Col, Form, Jumbotron, Row, Table, useAccordionToggle} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {FcCollapse, FcExpand} from "react-icons/fc";
import {BiSearchAlt} from "react-icons/bi";
import {useTranslation} from "react-i18next";
import _ from "lodash";

import {SoaanalyticsService} from "../../../../services";
import {NotificationManager} from "../../../../soajs/urac/components";
import AutoPagination from "../../../../lib/AutoPagination";

import ProgramsChart from "./charts/Programs"
import MethodsChart from "./charts/Methods"
import GroupsChart from "./charts/Groups"

import process from "./lib/process";

const soaanalyticsService = SoaanalyticsService.getService();

function CustomToggle({children, eventKey, handleClick}) {
	const decoratedOnClick = useAccordionToggle(eventKey, () => {
		handleClick();
	});
	return (
		<div className="float-left" type="button" onClick={decoratedOnClick}>
			{children}
		</div>
	);
}

const initCriteria = {
	"type": "service",
	"includeSOAJS": false,
	"serviceName": "",
	"serviceGroup": "",
	"version": "latest",
	"tags": [],
	"programs": [],
	"attributes": {}
};

async function onLoad(setFields, setPagination, currentPage, setFacets, isSubscribed, criteria) {
	try {
		let c = {
			"limit": 2000,
			"start": (currentPage - 1) * 2000,
			"type": criteria.type,
			"includeSOAJS": criteria.includeSOAJS,
			"version": criteria.version,
			"keywords": {
				"serviceName": criteria.serviceName,
				"serviceGroup": criteria.serviceGroup
			},
			"tags": criteria.tags,
			"programs": criteria.programs,
			"attributes": criteria.attributes
		};
		const response = await soaanalyticsService.getDashboardServices(c);
		if (isSubscribed) {
			if (response && response.data && Array.isArray(response.data) && response.data.length > 0) {
				let dataProcessed = process(response.data);
				setFields(dataProcessed);
				setPagination(
					{
						"totalItems": response.data.length,
						"maxSize": 10,
						"itemsPerPage": c.limit
					}
				);
				setFacets({
					"tags": response.tags || [], "programs": response.programs || [], "attributes": response.attributes || {}
				});
			}
		}
	} catch (e) {
		NotificationManager.error(e.message);
	}
}

export default function ApiMetrics() {
	const {t} = useTranslation(["common", "soajs"]);
	const [fields, setFields] = useState(null);
	const [activeKey, setActiveKey] = useState(0);
	const [facets, setFacets] = useState({"tags": [], "programs": [], "attributes": {}});
	const [currentPage, setCurrentPage] = useState(1);
	const [criteria, setCriteria] = useState(initCriteria);
	const [pagination, setPagination] = useState({
		"totalItems": 1, "maxSize": 1, "itemsPerPage": 1
	});
	
	function onChangeProgram(checked, program) {
		let obj = criteria;
		if (checked) {
			obj.programs.push(program);
		} else {
			_.pullAll(obj.programs, [program]);
		}
		setCriteria({...obj});
	}
	
	function onChangeTag(checked, tag) {
		let obj = criteria;
		if (checked) {
			obj.tags.push(tag);
		} else {
			_.pullAll(obj.tags, [tag]);
		}
		setCriteria({...obj});
	}
	
	function onChangeAttribute(checked, attribute, tag) {
		let obj = criteria;
		if (checked) {
			if (!obj.attributes[attribute]) {
				obj.attributes[attribute] = [];
			}
			obj.attributes[attribute].push(tag);
		} else {
			if (obj.attributes[attribute]) {
				_.pullAll(obj.attributes[attribute], [tag]);
				if (obj.attributes[attribute].length === 0) {
					delete obj.attributes[attribute];
				}
			}
		}
		setCriteria({...obj});
	}
	
	function handleCriteriaChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		setCriteria({
			...criteria,
			[event.target.id]: value
		});
	}
	
	async function reLoad(page) {
		await onLoad(setFields, setPagination, page || currentPage, setFacets, true, criteria)
	}
	
	async function handleSearch(event) {
		event.preventDefault();
		setCurrentPage(1);
		await onLoad(setFields, setPagination, 1, setFacets, true, criteria);
	}
	
	useEffect(() => {
		let isSubscribed = true;
		onLoad(setFields, setPagination, 1, setFacets, isSubscribed, initCriteria);
		return () => (isSubscribed = false);
	}, []);
	
	return (
		<>
			<Jumbotron className="p-3">
				<Form onSubmit={handleSearch}>
					<Row className="small">
						<Col xs={3}>
							<Form.Control
								placeholder={t("soajs:fields.ServiceName")}
								id="serviceName"
								size="sm"
								autoFocus
								value={criteria.serviceName}
								onChange={handleCriteriaChange}
							/>
						</Col>
						<Col xs={3}>
							<Form.Control
								placeholder={t("soajs:fields.ServiceGroup")}
								id="serviceGroup"
								size="sm"
								autoFocus
								value={criteria.serviceGroup}
								onChange={handleCriteriaChange}
							/>
						</Col>
						<Col xs={3}>
							<Form.Control as="select" id="version"
							              defaultValue={criteria.version}
							              onChange={handleCriteriaChange}
							>
								<option value="latest">Only last version</option>
								<option value="all">All versions</option>
							</Form.Control>
						</Col>
						<Col xs={3} className="mt-2">
							<Form.Check
								id="includeSOAJS"
								label="Include SOAJS APIs"
								defaultChecked={criteria.includeSOAJS}
								onChange={handleCriteriaChange}
							/>
						</Col>
					</Row>
					<hr/>
					<Row>
						<Col>
							<Accordion activeKey={activeKey}>
								<CustomToggle
									eventKey={1}
									handleClick={() => {
										if (activeKey === 1) {
											setActiveKey(null);
										} else {
											setActiveKey(1);
										}
									}}
								>
									{activeKey === 1 ? <FcExpand className="mr-1"/> :
										<FcCollapse className="mr-1"/>} <span
									className="small">Advanced Filters</span>
								</CustomToggle>
								
								<Button className="float-right" variant="dark" size="sm" onClick={handleSearch}>
									<BiSearchAlt/> {t("soajs:buttons.Search")}
								</Button>
								<span className="clearfix"></span>
								<Accordion.Collapse eventKey={1}>
									<>
										<hr className="mb-1"/>
										<Row className="mt-1 small">
											<Col xs={1}>
												Programs
											</Col>
											<Col>
												<CardDeck style={{"maxHeight": "3rem", "overflowY": "auto"}}>
													{facets.programs.map((program, index) => (
														<Card key={index} className="m-1 p-1" bg="light"
														      style={{"minWidth": '11rem', "maxWidth": '11rem'}}>
															<Card.Body className="p-1">
																<Form.Check key={index + 1} type="checkbox"
																            label={program}
																            defaultChecked={criteria.programs.includes(program)}
																            onChange={(event) => {
																	            onChangeProgram(event.target.checked, program)
																            }}
																/>
															</Card.Body>
														</Card>
													))}
												</CardDeck>
											</Col>
										</Row>
										<hr className="mt-1 mb-1"/>
										<Row className="mt-1 small">
											<Col xs={1}>
												Tags
											</Col>
											<Col>
												<CardDeck style={{"maxHeight": "6rem", "overflowY": "auto"}}>
													{facets.tags.map((tag, index) => (
														<Card key={index} className="m-1 p-1" bg="light"
														      style={{"minWidth": '11rem', "maxWidth": '11rem'}}>
															<Card.Body className="p-1">
																<Form.Check key={index + 1} type="checkbox"
																            label={tag}
																            defaultChecked={criteria.tags.includes(tag)}
																            onChange={(event) => {
																	            onChangeTag(event.target.checked, tag)
																            }}
																/>
															</Card.Body>
														</Card>
													))}
												</CardDeck>
											</Col>
										</Row>
										<hr className="mt-1 mb-1"/>
										<Row className="mt-1 small">
											<Col xs={1}>
												Attributes
											</Col>
											<Col>
												{Object.keys(facets.attributes).map((attribute, index) => (
													<div key={index}>
														<Row key={index} className="mt-2">
															<Col xs={1}>
																{attribute}
															</Col>
															<Col>
																<CardDeck
																	style={{"maxHeight": "7rem", "overflowY": "auto"}}>
																	{facets.attributes[attribute].map((tag, index) => (
																		<Card key={index} className="m-1 p-1" bg="light"
																		      style={{
																			      "minWidth": '11rem',
																			      "maxWidth": '11rem'
																		      }}>
																			<Card.Body className="p-1">
																				<Form.Check key={index + 1}
																				            type="checkbox"
																				            label={tag}
																				            defaultChecked={criteria.attributes[attribute] && criteria.attributes[attribute].includes(tag)}
																				            onChange={(event) => {
																					            onChangeAttribute(event.target.checked, attribute, tag)
																				            }}
																				/>
																			</Card.Body>
																		</Card>
																	))}
																</CardDeck>
															</Col>
														</Row>
														<hr className="mt-1 mb-1"/>
													</div>
												))}
											</Col>
										</Row>
									</>
								</Accordion.Collapse>
							</Accordion>
						</Col>
					</Row>
				</Form>
			</Jumbotron>
			{
				fields ? (
					<div>
						<Row>
							<Col>
								<div style={{"height": "350px"}}>
									<ProgramsChart
										data={fields.programs}
									/>
								</div>
							</Col>
							<Col>
								<div style={{"height": "350px"}}>
									<MethodsChart
										data={fields.methods}
									/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<div style={{"height": "600px"}}>
									<GroupsChart
										data={fields.groups}
									/>
								</div>
							</Col>
						</Row>
						<hr/>
						<Table responsive striped hover size="sm">
							<thead className="text-light bg-dark">
							<tr>
								<th>{t("soajs:fields.ServiceName")}</th>
								<th>{t("soajs:fields.ServiceGroup")}</th>
								<th>{t("soajs:fields.Program")}</th>
								<th className="text-center">{t("soajs:fields.NbrApi")}</th>
								<th className="text-center">{t("soajs:fields.Version")}</th>
							</tr>
							</thead>
							<tbody>
							{fields.services.map((srv, index) => (
								<tr key={index}>
									<td>{srv.serviceName}</td>
									<td className="small">{srv.serviceGroup}</td>
									<td className="small">{srv.program.join(", ")}</td>
									<td className="small text-center">{srv.apis}</td>
									<td className="small text-center">{srv.version}</td>
								</tr>
							))}
							</tbody>
						</Table>
						
						<div className="float-right mt-3">
							<AutoPagination
								currentPage={currentPage}
								totalItems={pagination.totalItems}
								itemsPerPage={pagination.itemsPerPage}
								maxSize={pagination.maxSize}
								onClick={(p) => {
									setCurrentPage(p);
									reLoad(p);
								}}
							/>
						</div>
						<div className="clearfix"></div>
					</div>
				) : null
			}
		</>
	);
}