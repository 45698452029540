import {Button, Form, Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {NotificationManager} from "../../../../soajs/urac/components";
import {ConsoleService} from "../../../../services";
import deepSet from "../../../../soajs/libs/deepSetLib";

const consoleService = ConsoleService.getService();
const initFields = {
	"id": "",
	"name": "",
	"description": ""
};

export default function UpdateModal({modalOpt, setModalOpt, handleAction}) {
	const {t} = useTranslation(["common", "soajs"]);
	const handleClose = () => setModalOpt({"show": false, item: null});
	
	const [fields, setFields] = useState(initFields);
	
	function validateForm() {
		return (
			fields.name.length > 0 &&
			fields.description.length > 0
		);
	}
	
	function handleFieldChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		let obj = fields;
		deepSet(obj, event.target.id, value);
		setFields({...obj});
	}
	
	async function handleSubmit() {
		try {
			await consoleService.updateCollection(fields.id, fields);
			NotificationManager.success(t("soajs:messages.collectionUpdate"));
			handleAction();
			handleClose();
		} catch
			(e) {
			NotificationManager.error(e.message);
		}
	}
	
	useEffect(() => {
		let isSubscribed = true;
		if (isSubscribed && modalOpt && modalOpt.item) {
			setFields(Object.assign({}, initFields, modalOpt.item));
		}
		return () => (isSubscribed = false);
	}, [modalOpt]);
	
	return (
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>{t("soajs:titles.collectionUpdate")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group>
						<Form.Label>{t("soajs:fields.Name")}</Form.Label>
						<Form.Control
							id="name"
							size="sm"
							value={fields.name}
							onChange={handleFieldChange}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>{t("soajs:fields.Description")}</Form.Label>
						<Form.Control
							id="description"
							size="sm"
							as="textarea"
							rows={3}
							value={fields.description}
							onChange={handleFieldChange}
						/>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="warning" onClick={handleClose}>
					{t("soajs:buttons.Cancel")}
				</Button>
				<Button
					variant="success"
					onClick={handleSubmit}
					disabled={!validateForm()}
				>
					{t("soajs:buttons.Update")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}