import { Button, Modal, Form, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { NotificationManager } from "../../../../../../soajs/urac/components";
import { UracService } from "../../../../../../soajs/urac/services";

const uracService = UracService.getService();
const initFields = {
	"id": "",
	"password": "",
	"confirmPassword": ""
};
export default function PasswordModal({ modalOpt, setModalOpt }) {
	const { t } = useTranslation(["common", "soajs"]);
	const handleClose = () => setModalOpt({ "show": false, opts: null });
	const [fields, setFields] = useState({ ...initFields });

	function validateForm() {
		return (
			fields.id.length > 4 &&
			fields.password.length > 4 &&
			fields.password === fields.confirmPassword
		);
	}

	function handleFieldChange(event) {
		setFields({
			...fields,
			[event.target.id]: event.target.value
		});
	}

	async function handleSubmit() {
		try {
			await uracService.changeUserPasswordProxy(fields, modalOpt.opts.extKey, modalOpt.opts.env);
			NotificationManager.success(t("soajs:messages.changePasswordUser"));
			handleClose();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}

	useEffect(() => {
		let isSubscribed = true;

		async function onLoad() {
			try {
				if (modalOpt.opts && modalOpt.opts.userId) {
					if (isSubscribed) {
						setFields({ ...initFields, ...{ "id": modalOpt.opts.userId } });
					}

				} else if (isSubscribed) {
					setFields({ ...initFields });
				}
			} catch (e) {
				NotificationManager.error(e.message);
			}
		}

		onLoad();
		return () => (isSubscribed = false);
	}, [modalOpt.opts]);

	return (
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>{t("soajs:titles.changePasswordUser")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={3}>{t("soajs:fields.Password")}</Form.Label>
							<Col sm={9}>
								<Form.Control
									id="password"
									size="sm"
									value={fields.password}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>

						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={3}>{t("soajs:fields.ConfirmPassword")}</Form.Label>
							<Col sm={9}>
								<Form.Control
									id="confirmPassword"
									size="sm"
									value={fields.confirmPassword}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="warning" onClick={handleClose}>
					{t("soajs:buttons.Cancel")}
				</Button>
				<Button variant="success" onClick={handleSubmit}
					disabled={!validateForm()}
				>
					{t("soajs:buttons.Change")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}