import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import {Link} from 'react-router-dom';
import {useAppContext} from "../../libs/contextLib";
import {useFormFields} from "../../libs/hooksLib";
import {LoaderButton} from "../../helpers";

import {NotificationManager, ThirdPartyLogin} from '../components';
import {AbilitiesService, LocalStorageService, OauthService, UracService} from "../services";
import {useTranslation} from "react-i18next";

const localStorageService = LocalStorageService.getService();
const oauthService = OauthService.getService();
const uracService = UracService.getService();
const abilitiesService = AbilitiesService.getService();

export default function Login() {
	const {t} = useTranslation(["common", "urac"]);
	const {isAuthenticated, userHasAuthenticated, setFirstName, ability} = useAppContext();
	const [isLoading, setIsLoading] = useState(false);
	const [isThirdParty, setIsThirdParty] = useState(false);
	const [loginOptions, setLoginOptions] = useState({
		"local": {"available": true},
		"thirdparty": []
	});
	const [fields, handleFieldChange] = useFormFields({
		email: "",
		password: ""
	});
	
	useEffect(() => {
		let isSubscribed = true;
		
		async function onLoad() {
			try {
				if (!isAuthenticated) {
					const res = await oauthService.availableLogin();
					// let res = {
					// 	"local": {"available": true},
					// 	"thirdparty": ["facebook", "google", "twitter", "apple"]
					// };
					if (isSubscribed) {
						setLoginOptions(res);
					}
				}
			} catch (e) {
				//NotificationManager.error(e.message);
			}
		}
		
		onLoad();
		return () => (isSubscribed = false);
	}, [isAuthenticated]);
	
	function validateForm() {
		return fields.email.length > 0 && fields.password.length > 0;
	}
	
	async function handleSubmit(event) {
		event.preventDefault();
		
		setIsLoading(true);
		
		try {
			await oauthService.login(fields.email, fields.password);
			await uracService.getUser();
			await abilitiesService.createUserAbilities(ability, true);
			setIsLoading(false);
			setFirstName(localStorageService.getFirstname());
			userHasAuthenticated(true);
		} catch (e) {
			NotificationManager.error(e.message);
			setIsLoading(false);
		}
		
	}
	
	async function handleThirdParty(event, strategy) {
		event.preventDefault();
		
		setIsThirdParty(true);
		
		let key = null;
		if (window.apiSettings && window.apiSettings.key) {
			key = window.apiSettings.key;
		}
		let apiEndpoint = null;
		if (window.apiSettings && window.apiSettings.api) {
			apiEndpoint = window.apiSettings.api;
		}
		if (key && apiEndpoint) {
			window.location = apiEndpoint + "/oauth/passport/login/" + strategy + "?key=" + key;
		} else {
			NotificationManager.error(strategy + " login is not available at this time");
		}
		
		setIsThirdParty(false);
	}
	
	return (
		<div className="URAC">
			<Form onSubmit={handleSubmit}>
				<Form.Group controlId="email">
					<Form.Label>{t("urac:Email or Username")}</Form.Label>
					<Form.Control
						autoFocus
						value={fields.email}
						onChange={handleFieldChange}
					/>
				</Form.Group>
				<Form.Group controlId="password">
					<Form.Label>{t("urac:Password")}</Form.Label>
					<Form.Control
						type="password"
						value={fields.password}
						onChange={handleFieldChange}
					/>
				</Form.Group>
				<Form.Text className="pb-3 text-right" muted>
					<Link to="/forgot-password">{t("urac:Forgot Password")}</Link>
				</Form.Text>
				<LoaderButton
					block
					type="submit"
					isLoading={isLoading}
					disabled={!validateForm()}
				>
					{t("urac:button.Login")}
				</LoaderButton>
				<ThirdPartyLogin
					strategies={loginOptions.thirdparty}
					onRequest={handleThirdParty}
					isThirdParty={isThirdParty}
				/>
			</Form>
		</div>
	);
}
