import { Alert, Button, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GoDiffAdded } from "react-icons/go";
import { ImDrawer } from "react-icons/im";


import { NotificationManager } from "../../../../../soajs/urac/components";
import { UracService } from "../../../../../soajs/urac/services";

// import Edit from "../../../../buttons/Edit";
import Delete from "../../../../buttons/Delete";
import View from "../../../../buttons/View";

import InfoModal from "./Group/InfoModal"
import DeleteModal from "./Group/DeleteModal";

const uracService = UracService.getService();

async function onLoad(setFields, extKey, env, isSubscribed, tenantId) {
	try {
		const users = await uracService.getGroupsProxy_v2(extKey, tenantId, env)

		if (users && Array.isArray(users)) {
			if (isSubscribed) {
				setFields(users);
			}
		} else {
			if (isSubscribed) {
				setFields([]);
			}
		}
	} catch (e) {
		NotificationManager.error(e.message);
	}
}

export default function TenantGroups({ extKey, env, tenantId }) {
	const { t } = useTranslation(["common", "soajs"]);
	const [fields, setFields] = useState([]);


	const [modalDeleteOpt, setModalDeleteOpt] = useState({ "show": false, opts: null });
	const modalDeleteOptShow = (opt) => setModalDeleteOpt({ "show": true, opts: opt });

	const [modalInfoOpt, setModalInfoOpt] = useState({ "show": false, opts: null });
	const modalInfoOptShow = (opt) => setModalInfoOpt({ "show": true, opts: opt });

	async function fetch() {
		if (extKey && env) {
			await onLoad(setFields, extKey, env, true, tenantId);
		}
	}

	useEffect(() => {
		let isSubscribed = true;
		if (extKey && env) {
			onLoad(setFields, extKey, env, isSubscribed, tenantId).catch();
		}
		return () => (isSubscribed = false);
	}, [extKey, env, tenantId]);

	return (
		<>

			<DeleteModal
				modalOpt={modalDeleteOpt}
				setModalOpt={setModalDeleteOpt}
				handleAction={async () => {
					onLoad(setFields, extKey, env, true, tenantId).catch()
				}}
			/>
			<InfoModal
				modalOpt={modalInfoOpt}
				setModalOpt={setModalInfoOpt}
			/>

			<Alert variant="light">
				<Button
					className="float-right ml-1"
					variant="primary"
					size="sm"
					onClick={fetch}>
					<ImDrawer /> {t("soajs:buttons.Fetch")}
				</Button>

				<Button
					className="float-right"
					variant="success"
					size="sm"
					disabled>
					<GoDiffAdded /> {t("soajs:buttons.Add")}
				</Button>

				<div className="clearfix"></div>
			</Alert>
			<Table responsive striped hover size="sm">
				<thead className="text-light bg-dark">
					<tr>
						<th>{t("soajs:buttons.Actions")}</th>
						<th>{t("soajs:fields.Code")}</th>
						<th>{t("soajs:fields.Name")}</th>
						<th>{t("soajs:fields.Description")}</th>
					</tr>
				</thead>
				<tbody>
					{fields && fields.map((rec, index) => (
						<tr key={index}>
							<td>
								{/* <Edit
									className="mr-1 p-1"
									disabled
								/>*/}
								<Delete
									className="mr-1 p-1"
									onClick={() => {
										modalDeleteOptShow({ groupId: rec._id, extKey, env });
									}}
								/>
								<View
									className="mr-1 p-1"
									onClick={() => {
										modalInfoOptShow(rec);
									}}
								/>
							</td>
							<td>{rec.code}</td>
							<td>{rec.name}</td>
							<td>{rec.description}</td>
						</tr>
					))}
				</tbody>
			</Table>
		</>
	);
}