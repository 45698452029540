import Template from "../Template/Template";
import React, {useState} from "react";

import UpdateModal from "./EditModal";
import AddModal from "./AddModal";
import DeleteModal from "../Template/DeleteModal";
import {useAppContext} from "../../../../soajs/libs/contextLib";


export default function Resource() {
	const {ability} = useAppContext();
	
	const [addModalOpt, setAddModalOpt] = useState({"show": false, "reLoad": null});
	const handleAddShow = (reLoad) => setAddModalOpt({"show": true, "reLoad": reLoad});
	
	const [updateModalOpt, setUpdateModalOpt] = useState({"show": false, "item": null, "reLoad": null});
	const handleUpdateShow = (item, reLoad) => setUpdateModalOpt({"show": true, "item": item, "reLoad": reLoad});
	
	const [deleteModalOpt, setDeleteModalOpt] = useState({"show": false, "item": null, "reLoad": null});
	const handleDeleteShow = (item, reLoad) => setDeleteModalOpt({"show": true, "item": item, "reLoad": reLoad});
	
	function EditResource(item, version, cb) {
		handleUpdateShow({"record": item, "version": version}, cb);
	}
	
	function AddResource(cb) {
		handleAddShow(cb);
	}
	
	function DeleteResource(item, cb) {
		handleDeleteShow(item, cb);
	}
	
	return (
		<>
			<DeleteModal
				modalOpt={deleteModalOpt}
				setModalOpt={setDeleteModalOpt}
			/>
			<UpdateModal
				modalOpt={updateModalOpt}
				setModalOpt={setUpdateModalOpt}
			/>
			<AddModal
				modalOpt={addModalOpt}
				setModalOpt={setAddModalOpt}
			/>
			<Template
				type={"resource"}
				editItem={ability.can('market_item_resource', 'add') ? EditResource : null}
				addItem={ability.can('market_item_resource', 'add') ? AddResource : null}
				deleteItem={ability.can('market_item', 'delete') ? DeleteResource : null}
			/>
		</>
	);
}
