import { Button, Modal, Form } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { NotificationManager } from "../../../../soajs/urac/components";
import { ConsoleService } from "../../../../services";

const consoleService = ConsoleService.getService();


export default function AddModal({ modalOpt, setModalOpt, handleAction, code }) {
	const { t } = useTranslation(["common", "soajs"]);
	const handleClose = () => setModalOpt({ "show": false, item: null });
	const [fields, setFields] = useState({
		"name": "",
		"description": "",
		"env": ""
	});

	function validateForm() {
		let valid = true;
		valid = (valid && fields.name.length > 0);
		if (fields.text) {
			valid = (valid && fields.valueText.length > 0);
		}
		return (
			valid
		);
	}

	function handleFieldChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		setFields({
			...fields,
			[event.target.id]: value
		});
	}

	async function handleSubmit() {
		try {
			let data = {
				"name": fields.name,
				"description": fields.description,
				"env": code
			};
			await consoleService.addCollection(data);
			NotificationManager.success(t("soajs:messages.addCollection"));
			handleAction();
			handleClose();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}

	useEffect(() => {
		let isSubscribed = true;
		if (isSubscribed && modalOpt) {
			setFields({
				"name": "",
				"description": "",
				"env": ""
			});
		}
		return () => (isSubscribed = false);
	}, [modalOpt, code]);

	return (
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>{t("soajs:titles.collectionAdd")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group>
						<Form.Label>{t("soajs:fields.Name")}</Form.Label>
						<Form.Control
							id="name"
							size="sm"
							value={fields.name}
							onChange={handleFieldChange}
						/>
					</Form.Group>
					<Form.Group style={{ "height": "400px" }}>
							<Form.Control
								id="description"
								size="sm"
								as="textarea"
								rows={15}
								value={fields.description}
								onChange={handleFieldChange}
							/>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="warning" onClick={handleClose}>
					{t("soajs:buttons.Cancel")}
				</Button>
				<Button variant="success" onClick={handleSubmit}
					disabled={!validateForm()}
				>
					{t("soajs:buttons.Add")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}