import { Button, Form, Table, Alert, Col, Row } from "react-bootstrap";
import { BiSearchAlt } from "react-icons/bi";
import { GoDiffAdded } from "react-icons/go";
import { GiBroadDagger } from "react-icons/gi";
import { ImDrawer } from "react-icons/im";

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Edit from "../../../../buttons/Edit";
import Delete from "../../../../buttons/Delete";
import Uninvite from "../../../../buttons/Uninvite";
import View from "../../../../buttons/View";
import Password from "../../../../buttons/Password";

import InfoModal from "./User/InfoModal";
import AddModal from "./User/AddModal";
import UninviteModal from "./User/UninviteModal";
import DeleteModal from "./User/DeleteModal";
import InviteModal from "./User/InviteModal";
import PasswordModal from "./User/PasswordModal";
import EditModal from "./User/EditModal";

import { NotificationManager } from "../../../../../soajs/urac/components";
import { UracService } from "../../../../../soajs/urac/services";
import AutoPagination from "../../../../../lib/AutoPagination";

const uracService = UracService.getService();

function getTenantGroups({ user, tenantId }) {
	let groups = null;
	if (user.config && user.config.allowedTenants && user.config.allowedTenants.length > 0) {
		for (let i = 0; i < user.config.allowedTenants.length; i++) {
			if (user.config.allowedTenants[i].tenant && user.config.allowedTenants[i].tenant.id === tenantId) {
				if (user.config.allowedTenants[i].groups) {
					groups = user.config.allowedTenants[i].groups
				} else {
					groups = null;
				}
				break;
			}
		}
	}
	return groups;
}

function PrintGroups({ user, tenantId }) {
	let groups = getTenantGroups({ user, tenantId });
	return (
		<>
			{groups ? groups.join(", ") : ""}
		</>
	)
}

async function onLoad(setFields, extKey, scope, env, criteria, page, setPagination, isSubscribed, tenantId) {
	try {
		let c = { "limit": 50, "start": (page - 1) * 50 };
		if (criteria) {
			if (criteria.keywords) {
				c.keywords = criteria.keywords;
			}
		}
		const userv2 = await uracService.getUsersProxy_v2(extKey, tenantId, scope, criteria, env);
		const users = userv2.items;
		const count = userv2.items;
		if (isSubscribed) {
			setPagination(
				{
					"totalItems": count,
					"maxSize": 10,
					"itemsPerPage": c.limit
				}
			);
		}
		if (users && Array.isArray(users)) {
			if (isSubscribed) {
				setFields(users);
			}
		} else {
			if (isSubscribed) {
				setFields([]);
			}
		}
	} catch (e) {
		NotificationManager.error(e.message);
	}
}

export default function TenantUsers({ extKey, tenantId, tenantCode, scope, env }) {
	const { t } = useTranslation(["common", "soajs"]);
	const [fields, setFields] = useState([]);

	const [criteria, setCriteria] = useState({
		"keywords": ""
	});
	const [currentPage, setCurrentPage] = useState(1);
	const [pagination, setPagination] = useState({
		"totalItems": 1, "maxSize": 1, "itemsPerPage": 1
	});

	const [modalInfoOpt, setModalInfoOpt] = useState({ "show": false, opts: null });
	const modalInfoOptShow = (opt) => setModalInfoOpt({ "show": true, opts: opt });

	const [modalAddOpt, setModalAddOpt] = useState({ "show": false, opts: null });
	const modalAddOptShow = (opt) => setModalAddOpt({ "show": true, opts: opt });

	const [modalDeleteOpt, setModalDeleteOpt] = useState({ "show": false, opts: null });
	const modalDeleteOptShow = (opt) => setModalDeleteOpt({ "show": true, opts: opt });

	const [modalUninviteOpt, setModalUninviteOpt] = useState({ "show": false, opts: null });
	const modalUninviteOptShow = (opt) => setModalUninviteOpt({ "show": true, opts: opt });

	const [modalInviteOpt, setModalInviteOpt] = useState({ "show": false, opts: null });
	const modalInviteOptShow = (opt) => setModalInviteOpt({ "show": true, opts: opt });

	const [modalPasswordOpt, setModalPasswordOpt] = useState({ "show": false, opts: null });
	const modalPasswordOptShow = (opt) => setModalPasswordOpt({ "show": true, opts: opt });

	const [modalEditOpt, setModalEditOpt] = useState({ "show": false, opts: null });
	const modalEditOptShow = (opt) => setModalEditOpt({ "show": true, opts: opt });

	async function fetch(page) {
		if (extKey && env) {
			await onLoad(setFields, extKey, scope, env, criteria, page || currentPage, setPagination, true, tenantId);
		}
	}

	async function handleSearch(event) {
		event.preventDefault();
		setCurrentPage(1);
		await fetch(1);
	}

	function handleCriteriaChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		if (event.target.type === 'number') {
			value = parseInt(value);
		}
		setCriteria({
			...criteria,
			[event.target.id]: value
		});
	}

	useEffect(() => {
		let isSubscribed = true;
		if (extKey && env) {
			onLoad(setFields, extKey, scope, env, null, 1, setPagination, isSubscribed, tenantId).catch();
		}
		return () => (isSubscribed = false);
	}, [extKey, scope, env, tenantId]);

	return (
		<>

			<EditModal
				modalOpt={modalEditOpt}
				setModalOpt={setModalEditOpt}
				handleAction={async () => {
					setCurrentPage(1);
					await fetch(1);
				}}
			/>
			<PasswordModal
				modalOpt={modalPasswordOpt}
				setModalOpt={setModalPasswordOpt}
			/>
			<InviteModal
				modalOpt={modalInviteOpt}
				setModalOpt={setModalInviteOpt}
				handleAction={async () => {
					setCurrentPage(1);
					await fetch(1);
				}}
			/>
			<DeleteModal
				modalOpt={modalDeleteOpt}
				setModalOpt={setModalDeleteOpt}
				handleAction={async () => {
					setCurrentPage(1);
					await fetch(1);
				}}
			/>
			<UninviteModal
				modalOpt={modalUninviteOpt}
				setModalOpt={setModalUninviteOpt}
				handleAction={async () => {
					setCurrentPage(1);
					await fetch(1);
				}}
			/>
			<AddModal
				modalOpt={modalAddOpt}
				setModalOpt={setModalAddOpt}
				handleAction={async () => {
					setCurrentPage(1);
					await fetch(1);
				}}
			/>
			<InfoModal
				modalOpt={modalInfoOpt}
				setModalOpt={setModalInfoOpt}
			/>
			<Alert variant="light">
				<div className="float-left">
					<Row>
						<Col>
							<Form.Control
								id="keywords"
								size="sm"
								value={criteria.keywords}
								onChange={handleCriteriaChange}
							/>
						</Col>
						<Col>
							<Button
								className="float-left"
								variant="dark"
								size="sm"
								onClick={handleSearch}>
								<BiSearchAlt /> {t("soajs:buttons.Search")}
							</Button>
						</Col>
					</Row>
				</div>
				<Button
					className="float-right ml-1"
					variant="primary"
					size="sm"
					onClick={fetch}>
					<ImDrawer /> {t("soajs:buttons.Fetch")}
				</Button>
				{scope === "myTenancy" && extKey && env &&
					<Button
						className="float-right"
						variant="success"
						size="sm"
						onClick={() => modalAddOptShow({ extKey, "tenant": { "id": tenantId, "code": tenantCode }, env })}
					>
						<GoDiffAdded /> {t("soajs:buttons.Add")}
					</Button>
				}
				{scope !== "myTenancy" && extKey && env &&
					<Button
						className="float-right"
						variant="success"
						size="sm"
						onClick={() => modalInviteOptShow({ extKey, "tenant": { "id": tenantId, "code": tenantCode }, env })}
					>
						<GiBroadDagger /> {t("soajs:buttons.Invite")}
					</Button>
				}
				<div className="clearfix"></div>
			</Alert>
			<Table responsive striped hover size="sm">
				<thead className="text-light bg-dark">
					<tr>
						<th>{t("soajs:buttons.Actions")}</th>
						<th>{t("soajs:fields.FirstName")}</th>
						<th>{t("soajs:fields.LastName")}</th>
						<th>{t("soajs:fields.Email")}</th>
						<th>{t("soajs:fields.Groups")}</th>
						<th>{t("soajs:fields.Username")}</th>
						<th>{t("soajs:fields.Status")}</th>
					</tr>
				</thead>
				<tbody>
					{fields && fields.map((user, index) => (
						<tr key={index}>
							<td>
								{scope === "myTenancy" &&
									<>
										<Password
											className="mr-1 p-1"

											onClick={() => {
												modalPasswordOptShow({ userId: user._id, extKey, env });
											}}
										/>
										<Edit
											className="mr-1 p-1"
											onClick={() => modalEditOptShow({ user, extKey, "tenant": { "id": tenantId, "code": tenantCode }, env })}
										/>
										<Delete
											className="mr-1 p-1"

											onClick={() => {
												modalDeleteOptShow({ userId: user._id, extKey, env });
											}}
										/>
									</>
								}
								{scope !== "myTenancy" &&
									<Uninvite
										className="mr-1 p-1"
										onClick={() => {
											modalUninviteOptShow({ userId: user._id, extKey, "tenant": { "id": tenantId, "code": tenantCode }, env });
										}}
									/>
								}
								<View
									className="mr-1 p-1"

									onClick={() => {
										modalInfoOptShow(user);
									}}
								/>
							</td>
							<td>{user.firstName}</td>
							<td>{user.lastName}</td>
							<td>{user.email}</td>
							<td>
								<PrintGroups
									user={user}
									tenantId={tenantId}
								/>
							</td>
							<td>{user.username}</td>
							<td>{user.status}</td>
						</tr>
					))}
				</tbody>
			</Table>
			<div className="float-right mt-3">
				<AutoPagination
					currentPage={currentPage}
					totalItems={pagination.totalItems}
					itemsPerPage={pagination.itemsPerPage}
					maxSize={pagination.maxSize}
					onClick={(p) => {
						setCurrentPage(p);
						fetch(p, null).catch();
					}}
				/>
			</div>
			<div className="clearfix"></div>
		</>
	);
}