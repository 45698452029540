import React, { useState, useEffect } from "react";
import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";
import { GoDiffAdded } from "react-icons/go";
import { arrayMoveImmutable } from "array-move";
import { ImDrawer } from "react-icons/im";
import { FcCollapse, FcExpand } from "react-icons/fc";
import { BiSearchAlt } from "react-icons/bi";

import { Button, Row, Col, Alert, Tabs, Tab, Form, Accordion, useAccordionToggle } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../../../soajs/libs/contextLib";
import { ConsoleService, SoaanalyticsService } from "../../../../services";
import { NotificationManager } from "../../../../soajs/urac/components";
import { JsonEditor } from "../../../../soajs/urac/components";
import deepSet from "../../../../soajs/libs/deepSetLib";
import EditButton from "../../../buttons/Edit";
import DeleteButton from "../../../buttons/Delete";
import AutoPagination from "../../../../lib/AutoPagination";

import AddModal from "./AddModal";
import DeleteModal from "./DeleteModal";
import UpdateModal from "./UpdateModal";

const itemInit = {
    "_id": "NEW",
    "name": "",
    "api": "",
    "method": "get",
    "headers": {},
    "query": {},
    "params": {},
    "body": {},
    "output": {}
};

const soaanalyticsService = SoaanalyticsService.getService();
const consoleService = ConsoleService.getService();

const methodVariant = {
    "get": "primary",
    "post": "success",
    "delete": "danger",
    "put": "warning",
    "patch": "info",
    "head": "secondary",
    "other": "light"
};
function getMethodVariant(method) {
    if (method && methodVariant[method]) {
        return methodVariant[method];
    } else {
        return methodVariant["other"];
    }
}

function CustomToggle({ children, eventKey, handleClick }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () => {
        handleClick();
    });
    return (
        <div className="float-left" type="button" onClick={decoratedOnClick}>
            {children}
        </div>
    );
}

async function onLoad(environment, setCollections, criteria, setItems, isSubscribed) {
    try {
        if (environment) {
            const collections = await consoleService.getCollections(environment.code);
            if (collections && isSubscribed) {
                setCollections(collections);
                const temp = collections.find(element => element._id === criteria.collection);
                if (temp && temp.apis) {
                    setItems([...temp.apis]);
                }
            }
        }
    } catch (e) {
        NotificationManager.error(e.message);
    }
}

export default function Workspace({ environment }) {
    const { t } = useTranslation(["common", "soajs"]);
    const [fields, setFields] = useState([]);
    const [activeKey, setActiveKey] = useState(0);
    const [registry, setRegistry] = useState(null);
    const [collections, setCollections] = useState([]);
    const [items, setItems] = useState([]);
    const [item, setItem] = useState({})
    const { ability } = useAppContext();
    const [criteria, setCriteria] = useState({
        "collection": "",
        "type": "service",
        "includeSOAJS": false,
        "serviceName": "",
        "serviceGroup": "",
        "serviceVersion": "",
        "route": "",
        "tenant": ""
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [pagination, setPagination] = useState({
        "totalItems": 1, "maxSize": 1, "itemsPerPage": 1
    });

    const [addModalOpt, setAddModalOpt] = useState({ "show": false });
    const handleAddShow = () => setAddModalOpt({ "show": true });
    const [modalUpdateOpt, setModalUpdateOpt] = useState({ "show": false, item: null });
    const modalUpdateOptShow = (item) => setModalUpdateOpt({ "show": true, item: item });
    const [modalDeleteOpt, setModalDeleteOpt] = useState({ "show": false, item: null });
    const modalDeleteOptShow = (item) => setModalDeleteOpt({ "show": true, item: item });


    function JsonEditor_onChange(json, what) {
        item[what] = json;
        setItem(item);
    }
    async function reLoad(doNotSetItem) {
        await onLoad(environment, setCollections, criteria, setItems, true);
        if (!doNotSetItem) {
            setItem({});
        }
    }
    useEffect(() => {
        let isSubscribed = true;
        (async () => {
            try {
                if (environment) {
                    const response = await consoleService.getRegistry(environment.code);
                    if (response && isSubscribed) {
                        setRegistry(response);
                        onLoad(environment, setCollections, criteria, setItems, isSubscribed).catch();
                    }
                }
            } catch (e) {
                NotificationManager.error(e.message);
            }
        })();
        return () => (isSubscribed = false);
    }, [environment, criteria]);

    const onSortEnd = async ({ oldIndex, newIndex }) => {
        const tempItems = arrayMoveImmutable(items, oldIndex, newIndex);
        setItems(tempItems);
        try {
            await consoleService.updateCollectionApis(criteria.collection, { "apis": tempItems });
            NotificationManager.success(t("soajs:messages.updateCollectionApis"));
        } catch (e) {
            NotificationManager.error(e.message);
        }
    };
    const selectItem = (value) => {
        setItem(value);
    };
    function handleCriteriaChange(event) {
        let value = event.target.value;
        let id = event.target.id;
        if (event.target.hasOwnProperty("checked")) {
            value = event.target.checked;
        }
        setCriteria({
            ...criteria,
            [id]: value
        });

        if (id === "collection") {
            if (!value || value === "") {
                setItems([]);
                setItem({});
            } else {
                const temp = collections.find(element => element._id === value);
                if (temp.apis) {
                    setItems(temp.apis);
                    setItem(itemInit);
                } else {
                    setItems([]);
                    setItem(itemInit);
                }
            }
        }
    }

    function handleFieldChange(event) {
        let value = event.target.value;
        let id = event.target.id;
        if (event.target.hasOwnProperty("checked")) {
            value = event.target.checked;
        }
        if (event.target.type === 'number') {
            value = parseInt(value);
        }

        deepSet(item, id, value);
        setItem({ ...item });
    }
    async function fetchItem() {
        item.output = {};
        setItem({ ...item });
        let url = registry.protocol + "://" + registry.apiPrefix + "." + registry.domain;
        let api = item.api;
        if (item.params && Object.keys(item.params).length) {
            for (const [key, value] of Object.entries(item.params)) {
                api = api.replace(`:${key}`, value);
            }
        }
        //https://www.npmjs.com/package/axios
        const config = {
            "method": item.method.toLowerCase(),
            "baseURL": url,
            "headers": { ...item.headers } || {},
            "url": api
        };
        if (item.body && Object.keys(item.body).length > 0) {
            config.data = item.body;
        }
        if (item.query && Object.keys(item.query).length > 0) {
            config.params = item.query;
        }
        if (!item.headers["Content-Type"]) {
            config.responseType = "json";
        }
        try {
            const response = await consoleService.fetchProxy(url, config);
            if (response) {
                item.output = response;
            }
        } catch (error) {
            item.output = error;
        }
        setItem({ ...item });
    }
    async function saveItem() {
        if (item._id === "NEW") {
            try {
                const data = {
                    "name": item.name,
                    "api": item.api,
                    "method": item.method,
                    "headers": item.headers,
                    "query": item.query,
                    "body": item.body,
                    "params": item.params
                };
                const response = await consoleService.addCollectionApi(criteria.collection, data);
                NotificationManager.success(t("soajs:messages.addCollectionApi"));
                item._id = response._id;
                items.unshift(item);
                setItems([...items]);
                setItem({ ...item });
            } catch (e) {
                NotificationManager.error(e.message);
            }
        } else {
            try {
                const data = {
                    "name": item.name,
                    "api": item.api,
                    "method": item.method,
                    "headers": item.headers,
                    "query": item.query,
                    "body": item.body,
                    "params": item.params
                };
                await consoleService.updateCollectionApi(criteria.collection, item._id, data);
                const itemIndex = items.findIndex(element => element._id === item._id);
                items[itemIndex] = { ...items[itemIndex], ...data };
                setItems([...items]);
                NotificationManager.success(t("soajs:messages.updateCollectionApi"));
            } catch (e) {
                NotificationManager.error(e.message);
            }
        }
    }
    async function deleteItem() {
        try {
            await consoleService.deleteCollectionApi(criteria.collection, item._id);
            NotificationManager.success(t("soajs:messages.itemDelete"));
            reLoad();
        } catch (e) {
            NotificationManager.error(e.message);
        }
    }

    const DragHandle = sortableHandle(() => <span>::</span>);
    const SortableItem = sortableElement(({ value }) => (
        <li
            style={
                {
                    "listStyleType": "none"
                }
            }
        >
            <Alert variant={getMethodVariant(value.method)} className="pl-1 mb-1 pt-1 pb-1">
                <Row>
                    <Col
                        style={
                            {
                                "overflow": "auto",
                                "display": "flex"
                            }
                        }
                    >
                        {ability.can('collection', 'update_apis') && <DragHandle />}
                        <Button size="sm" className="ml-2 mr-2 pl-1 pr-1 pt-0 pb-0"
                            onClick={() => { selectItem(value) }}
                            variant={getMethodVariant(value.method)}><span
                                className="small">{value.method.toUpperCase()}</span></Button>
                        <span style={{ "cursor": "pointer" }} className="small pt-1" onClick={() => { selectItem(value) }}>{value.name} {value.api}</span>
                    </Col>
                </Row>
            </Alert>
        </li>
    ));
    const SortableContainer = sortableContainer(({ children }) => {
        return (
            <ul
                className="pl-0 pt-2 pr-2 pb-2 mb-0"
                style={
                    {
                        "listStyleType": "none"
                    }
                }
            >
                {children}
            </ul>
        );
    });

    async function reloadAPI(page) {
        try {
            let c = {
                "limit": 6,
                "start": (page - 1) * 6,
                "type": criteria.type,
                "includeSOAJS": criteria.includeSOAJS,
                "keywords": {
                    "serviceName": criteria.serviceName,
                    "serviceGroup": criteria.serviceGroup,
                    "serviceVersion": criteria.serviceVersion,
                    "route": criteria.route
                }
            };
            const response = await soaanalyticsService.getApis(c);
            if (response && response.records) {
                setFields(response.records);
                setPagination(
                    {
                        "totalItems": response.count,
                        "maxSize": 10,
                        "itemsPerPage": response.limit
                    }
                );
            }
        } catch (e) {
            NotificationManager.error(e.message);
        }
    }
    async function handleSearch(event) {
        event.preventDefault();
        setCurrentPage(1);
        await reloadAPI(1);
    }

    return (
        environment && registry &&
        <>
            <UpdateModal
                modalOpt={modalUpdateOpt}
                setModalOpt={setModalUpdateOpt}
                handleAction={() => { reLoad(true); }}
            />
            <DeleteModal
                modalOpt={modalDeleteOpt}
                setModalOpt={setModalDeleteOpt}
                handleAction={reLoad}
            />
            <AddModal
                modalOpt={addModalOpt}
                setModalOpt={setAddModalOpt}
                handleAction={() => { reLoad(true); }}
                code={environment.code}
            />
            <Form>
                <Row style={{ "backgroundColor": "#e9ecef" }} className="p-2">
                    <Col sm={3} md={2} className="pl-0">
                        <h5 className="float-left mt-2">Collections</h5>
                    </Col>
                    <Col>
                        <Form.Control
                            as="select"
                            id="collection"
                            size="sm"
                            defaultValue={criteria.collection}
                            onChange={handleCriteriaChange}
                        >
                            <option value="">-- Select a collection --</option>
                            {collections.map((rec, index) => (
                                <option key={index} value={rec._id}>{rec.name}</option>
                            ))}
                        </Form.Control>
                    </Col>
                    <Col sm={3} md={2}>
                        {criteria.collection !== "" &&
                            <>
                                {ability.can('collection', 'delete') &&
                                    <DeleteButton
                                        className="float-left mr-1"
                                        onClick={() => {
                                            const temp = collections.find(element => element._id === criteria.collection);
                                            modalDeleteOptShow({
                                                "id": temp._id,
                                                "name": temp.name
                                            });
                                        }}
                                    />}
                                {ability.can('collection', 'update') &&
                                    <EditButton
                                        className="float-left mr-1"
                                        onClick={() => {
                                            const temp = collections.find(element => element._id === criteria.collection);
                                            modalUpdateOptShow({
                                                "id": temp._id,
                                                "name": temp.name,
                                                "description": temp.description
                                            })
                                        }}
                                    />}
                            </>
                        }
                    </Col>
                    <Col sm={3} md={2} className="pr-1">
                        {ability.can('collection', 'add') &&
                            <Button
                                className="float-right mb-1"
                                variant="success"
                                size="sm"
                                onClick={() => {
                                    handleAddShow();
                                }}
                            ><GoDiffAdded /> {t("soajs:buttons.Add")}</Button>}
                    </Col>
                </Row>
            </Form>
            <Row className="pt-3 pl-0">
                {items && criteria.collection !== "" &&
                    <Col sm={6} md={5} lg={4} className="pl-0">
                        <div
                            className="small mt-0"
                            style={
                                {
                                    "overflow": "scroll",
                                    "maxHeight": "650px",
                                    "backgroundColor": "#e9ecef"
                                }
                            }
                        >
                            <h6 className="float-left mt-2 ml-2">List of available requests</h6>
                            <Button
                                className="float-right mt-1 mr-2"
                                variant="info"
                                size="sm"
                                onClick={() => {
                                    setItem(itemInit);
                                }}
                            ><GoDiffAdded /> {t("soajs:buttons.New")}</Button>
                            <span className="clearfix"></span>
                            <SortableContainer onSortEnd={onSortEnd} useDragHandle>
                                {items.map((value, index) => (
                                    <SortableItem key={index} index={index} value={value} />
                                ))}
                            </SortableContainer>
                        </div>
                    </Col>
                }
                {item._id &&
                    <Col sm={6} md={7} lg={8}>
                        <Form>
                            <Row>
                                <Col style={{ "backgroundColor": "#e9ecef" }}>
                                    <Form.Group className="mt-2">
                                        <Form.Label>{t("soajs:fields.Name")}</Form.Label>
                                        <Form.Control
                                            id="name"
                                            size="sm"
                                            value={item.name}
                                            onChange={handleFieldChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col style={{ "backgroundColor": "#e9ecef" }}>
                                    <Form.Group className="mt-2">
                                        <Form.Label>{t("soajs:fields.Method")}</Form.Label>
                                        <Form.Control
                                            as="select"
                                            id="method"
                                            size="sm"
                                            value={item.method}
                                            onChange={handleFieldChange}
                                        >
                                            <option value="get">GET</option>
                                            <option value="put">PUT</option>
                                            <option value="post">POST</option>
                                            <option value="delete">DELETE</option>
                                            <option value="patch">PATCH</option>
                                            <option value="head">HEAD</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ "backgroundColor": "#e9ecef" }}>
                                    <Form.Group className="mb-0">
                                        <Form.Label>{t("soajs:fields.API")}</Form.Label>
                                        <Form.Control
                                            id="api"
                                            size="sm"
                                            value={item.api}
                                            onChange={handleFieldChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ "backgroundColor": "#e9ecef" }} className="pb-1">
                                    <Accordion activeKey={activeKey}>
                                        <CustomToggle
                                            eventKey={1}
                                            handleClick={() => {
                                                if (activeKey === 1) {
                                                    setActiveKey(null);
                                                } else {
                                                    setActiveKey(1);
                                                }
                                            }}
                                        >
                                            {activeKey === 1 ? <FcExpand className="mr-1" /> :
                                                <FcCollapse className="mr-1" />} <span
                                                    className="small">Advanced Filters</span>
                                        </CustomToggle>
                                        <div className="clearfix"></div>
                                        <Accordion.Collapse eventKey={1} className="p-2" style={{ "backgroundColor": "white" }}>
                                            <>
                                                <Row className="small mt-2">
                                                    <Col xs={4}>
                                                        <Form.Control
                                                            placeholder={t("soajs:fields.ServiceName")}
                                                            id="serviceName"
                                                            size="sm"
                                                            autoFocus
                                                            value={criteria.serviceName}
                                                            onChange={handleCriteriaChange}
                                                        />
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Control
                                                            placeholder={t("soajs:fields.ServiceVersion")}
                                                            id="serviceVersion"
                                                            size="sm"
                                                            autoFocus
                                                            value={criteria.serviceVersion}
                                                            onChange={handleCriteriaChange}
                                                        />
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Control
                                                            placeholder={t("soajs:fields.ServiceGroup")}
                                                            id="serviceGroup"
                                                            size="sm"
                                                            autoFocus
                                                            value={criteria.serviceGroup}
                                                            onChange={handleCriteriaChange}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="small mt-2 mb-2">
                                                    <Col xs={6} md={7}>
                                                        <Form.Control
                                                            placeholder={t("soajs:fields.Route")}
                                                            id="route"
                                                            size="sm"
                                                            autoFocus
                                                            value={criteria.route}
                                                            onChange={handleCriteriaChange}
                                                        />
                                                    </Col>
                                                    <Col xs={3}>
                                                        <Form.Check
                                                            id="includeSOAJS"
                                                            label="Include SOAJS APIs"
                                                            defaultChecked={criteria.includeSOAJS}
                                                            onChange={handleCriteriaChange}
                                                        />
                                                    </Col>
                                                    <Col xs={3} md={2}>
                                                        <Button className="float-right" variant="dark" size="sm" onClick={handleSearch}>
                                                            <BiSearchAlt /> {t("soajs:buttons.Search")}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                {fields.length > 0 ? (
                                                    <>
                                                        {fields.map((api, index) => (

                                                            <Alert className="m-1 p-1" key={index} variant={getMethodVariant(api.method)}>
                                                                <Row>
                                                                    <Col
                                                                        style={{ "cursor": "pointer" }}
                                                                        onClick={() => {
                                                                            item.api = "/" + api.serviceName + api.route;
                                                                            item.method = api.method.toLowerCase();
                                                                            setItem({ ...item });
                                                                        }}
                                                                    >
                                                                        <Button size="sm" className="mr-2 pl-1 pr-1 pt-0 pb-0 float-left"
                                                                            variant={getMethodVariant(api.method)}><span
                                                                                className="small">{api.method ? api.method.toUpperCase() : ""}</span></Button>
                                                                        <span className="small float-left font-weight-bold mr-1">{api.serviceName}</span>
                                                                        <span
                                                                            className="small float-left mr-2 font-weight-bold text-success"> v{api.v} </span>
                                                                        <span className="small float-left">{api.route}</span>
                                                                        <span className="small float-right text-info">{api.group}</span>
                                                                    </Col>
                                                                </Row>
                                                            </Alert>
                                                        ))}
                                                        <div className="float-right mt-3">
                                                            <AutoPagination
                                                                className="mb-2"
                                                                currentPage={currentPage}
                                                                totalItems={pagination.totalItems}
                                                                itemsPerPage={pagination.itemsPerPage}
                                                                maxSize={pagination.maxSize}
                                                                onClick={(p) => {
                                                                    setCurrentPage(p);
                                                                    reloadAPI(p);
                                                                }}
                                                            />
                                                        </div>
                                                    </>
                                                ) : null}
                                            </>
                                        </Accordion.Collapse>
                                    </Accordion>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Tabs
                                        defaultActiveKey="headers"
                                        id="api_tab"
                                        className="mb-2 mt-2"
                                    >
                                        <Tab eventKey="headers" title="Headers">
                                            <JsonEditor
                                                mode="code"
                                                json={item.headers || {}}
                                                onChange={(json) => JsonEditor_onChange(json, "headers")}
                                            />
                                        </Tab>
                                        <Tab eventKey="params" title="Params">
                                            <JsonEditor
                                                mode="code"
                                                json={item.params || {}}
                                                onChange={(json) => JsonEditor_onChange(json, "params")}
                                            />
                                        </Tab>
                                        <Tab eventKey="query" title="Query">
                                            <JsonEditor
                                                mode="code"
                                                json={item.query || {}}
                                                onChange={(json) => JsonEditor_onChange(json, "query")}
                                            />
                                        </Tab>
                                        <Tab eventKey="body" title="Body">
                                            <JsonEditor
                                                mode="code"
                                                json={item.body || {}}
                                                onChange={(json) => JsonEditor_onChange(json, "body")}
                                            />
                                        </Tab>
                                    </Tabs>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ "backgroundColor": "#e9ecef" }}>

                                    {ability.can('collection', 'delete_api') && item._id !== "NEW" &&
                                        <DeleteButton
                                            className="float-left mt-2"
                                            onClick={() => {
                                                deleteItem();
                                            }}
                                        />
                                    }
                                    {ability.can('collection', 'proxy') &&
                                        <Button
                                            className="float-right ml-1 mt-2 mb-2"
                                            variant="primary"
                                            size="sm"
                                            onClick={fetchItem}>
                                            <ImDrawer /> {t("soajs:buttons.Fetch")}
                                        </Button>
                                    }
                                    {ability.can('collection', 'update_api') && ability.can('collection', 'add_api') &&
                                        <Button
                                            className="float-right mt-2 mb-2"
                                            variant="success"
                                            size="sm"
                                            onClick={saveItem}>
                                            <GoDiffAdded /> {t("soajs:buttons.Save")}
                                        </Button>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mt-2">
                                    <JsonEditor
                                        mode={"view"}
                                        modes={["view", "preview"]}
                                        json={item.output || {}}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                }
            </Row>
        </>
    );
}