import Template from "../Template/Template";
import {useAppContext} from "../../../../soajs/libs/contextLib";
import React, {useState} from "react";
import DeleteModal from "../Template/DeleteModal";


export default function Frontend() {
	const {ability} = useAppContext();
	const [deleteModalOpt, setDeleteModalOpt] = useState({"show": false, "item": null, "reLoad": null});
	const handleDeleteShow = (item, reLoad) => setDeleteModalOpt({"show": true, "item": item, "reLoad": reLoad});
	
	function Delete(item, cb) {
		handleDeleteShow(item, cb);
	}
	
	return (
		<>
			<DeleteModal
				modalOpt={deleteModalOpt}
				setModalOpt={setDeleteModalOpt}
			/>
			<Template
				type={"static"}
				deleteItem={ability.can('market_item', 'delete') ? Delete : null}
			/>
		</>
	);
}
