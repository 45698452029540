import { Button, ListGroup, ListGroupItem, Modal, Accordion, Card, Form } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function InfoModal({ modalOpt, setModalOpt }) {
	const { t } = useTranslation(["common", "soajs"]);
	const [fields, setFields] = useState([]);

	const handleClose = () => setModalOpt({ "show": false, opts: null });

	useEffect(() => {
		let isSubscribed = true;
		if (modalOpt.opts && modalOpt.opts.config && modalOpt.opts.config.allowedTenants) {
			let _fields = [];
			for (let j = 0; j < modalOpt.opts.config.allowedTenants.length; j++) {
				let item = {};
				item.tenant = modalOpt.opts.config.allowedTenants[j].tenant;
				item.groups = modalOpt.opts.config.allowedTenants[j].groups;
				_fields.push(item);
			}
			if (isSubscribed) {
				setFields(_fields);
			}
		} else {
			if (isSubscribed) {
				setFields(null);
			}
		}
		return () => (isSubscribed = false);
	}, [modalOpt]);

	return (
		modalOpt.opts &&
		<Modal show={modalOpt.show} onHide={handleClose} animation={false}>
			<Modal.Header closeButton className="bg-secondary text-white">
				<Modal.Title>{t("soajs:titles.userInfo")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<ListGroup variant="flush">
					<ListGroupItem>
						<span className="font-weight-bold">
							{t("soajs:fields.ID")}:</span> {modalOpt.opts._id}
					</ListGroupItem>
					<ListGroupItem>
						<span className="font-weight-bold">
							{t("soajs:fields.FirstName")}:</span> {modalOpt.opts.firstName}
					</ListGroupItem>
					<ListGroupItem>
						<span className="font-weight-bold">
							{t("soajs:fields.LastName")}:</span> {modalOpt.opts.lastName}
					</ListGroupItem>
					<ListGroupItem>
						<span className="font-weight-bold">
							{t("soajs:fields.Email")}:</span> {modalOpt.opts.email}
					</ListGroupItem>

					{modalOpt.opts.groups && modalOpt.opts.groups.length > 0 &&
						<ListGroupItem>
							<span className="font-weight-bold">
								{t("soajs:fields.Groups")}:</span> {modalOpt.opts.groups ? modalOpt.opts.groups.join(", ") : ""}
						</ListGroupItem>
					}
				</ListGroup>
				<hr />
				<Accordion defaultActiveKey={1}>
					{fields && fields.map((item, index) => (
						<Card key={index + 1}>
							<Card.Header>
								<div>
									<Accordion.Toggle className="p-0" as={Button} variant="link"
										eventKey={index + 1}>
										{item.tenant.id} - {item.tenant.code}
									</Accordion.Toggle>
								</div>
							</Card.Header>
							<Accordion.Collapse eventKey={index + 1}>
								<Card.Body>
									{item.groups && item.groups.map((pack, index) => (
										<Form.Check key={index + 1} type="switch" disabled
											label={pack}
										/>
									))}
								</Card.Body>
							</Accordion.Collapse>
						</Card>
					))}
				</Accordion>
			</Modal.Body>
			<Modal.Footer className="bg-light">
				<Button variant="info" onClick={handleClose}>
					{t("soajs:buttons.Ok")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}