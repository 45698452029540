import {Badge, Button, Modal} from "react-bootstrap";
import React from "react";
import {useTranslation} from "react-i18next";

import {NotificationManager} from "../../../../soajs/urac/components";
import {MarketplaceService} from "../../../../services";

const marketplaceService = MarketplaceService.getService();

export default function DeleteModal({modalOpt, setModalOpt}) {
	const {t} = useTranslation(["common", "soajs"]);
	const handleClose = () => setModalOpt({"show": false, "item": null, "reload": null});
	
	async function handleSubmit() {
		try {
			await marketplaceService.deleteItem({"type": modalOpt.item.type, "name": modalOpt.item.name});
			NotificationManager.success(t("soajs:messages.delete_" + modalOpt.item.type));
			if (modalOpt.reLoad) {
				modalOpt.reLoad();
			}
			handleClose();
		} catch
			(e) {
			NotificationManager.error(e.message);
		}
	}
	
	return (
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>{t("soajs:titles.itemDelete")} <Badge
					variant="light">{modalOpt.item && modalOpt.item.name}</Badge>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{t("soajs:messages.deleteQuestion")}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" onClick={handleClose}>
					{t("soajs:buttons.Close")}
				</Button>
				<Button variant="danger" onClick={handleSubmit}>
					{t("soajs:buttons.Delete")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
