import { Button, Modal, Form, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import _ from "lodash";

import { NotificationManager } from "../../../../../../soajs/urac/components";
import { UracService } from "../../../../../../soajs/urac/services";

const uracService = UracService.getService();
const initFields = {
	"id": "",
	"username": "",
	"firstName": "",
	"lastName": "",
	"email": "",
	"phone": "",
	"status": "",
	"groups": []
};
export default function EditModal({ modalOpt, setModalOpt, handleAction }) {
	const { t } = useTranslation(["common", "soajs"]);
	const handleClose = () => setModalOpt({ "show": false, opts: null });
	const [fields, setFields] = useState({ ...initFields });
	const [groupsFields, setGroupsFields] = useState([]);
	const [groups, setGroups] = useState(null);

	function validateForm() {
		return (
			fields.id.length > 4 &&
			fields.username.length > 4 &&
			fields.firstName.length > 0 &&
			fields.lastName.length > 0 &&
			fields.email.length > 0
		);
	}

	function handleFieldChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		setFields({
			...fields,
			[event.target.id]: value
		});
	}

	async function handleSubmit() {
		try {
			await uracService.editUseProxy(fields, modalOpt.opts.extKey, modalOpt.opts.env);
			NotificationManager.success(t("soajs:messages.editUser"));
			handleAction();
			handleClose();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}

	function onChangeGroup(checked, code) {
		if (checked) {
			let g = groups
			if (!g) {
				g = [];
			}
			g.push(code);
			setGroups(g)
		} else {
			let g = groups
			if (g) {
				_.pull(g, code);
				setGroups(g);
			}
		}
	}

	useEffect(() => {
		let isSubscribed = true;

		async function onLoad() {
			try {
				if (modalOpt.opts) {
					const _groups = await uracService.getGroupsProxy_v2(modalOpt.opts.extKey, modalOpt.opts.tenant.id, modalOpt.opts.env)
					let _g = null;
					if (_groups) {
						_g = [];
						for (let i = 0; i < _groups.length; i++) {
							let _item = {
								"code": _groups[i].code,
								"name": _groups[i].name,
								"selected": false
							};
							if (modalOpt.opts.groups && modalOpt.opts.user.groups.includes(_item.code)) {
								_item.selected = true;
							}
							_g.push(_item);
						}
						if (isSubscribed) {
							setGroupsFields(_g);
							if (modalOpt.opts.user.groups && Array.isArray(modalOpt.opts.user.groups)) {
								setGroups([...modalOpt.opts.user.groups]);
							}
						}
					}
					if (isSubscribed) {
						setFields({
							"id": modalOpt.opts.user._id,
							"username": modalOpt.opts.user.username,
							"email": modalOpt.opts.user.email,
							"firstName": modalOpt.opts.user.firstName,
							"lastName": modalOpt.opts.user.lastName,
							"phone": modalOpt.opts.user.phone || "",
							"status": modalOpt.opts.user.status
						});
					}
				} else {
					if (isSubscribed) {
						setGroupsFields([]);
						setGroups(null);
						setFields(initFields);
					}
				}
			} catch (e) {
				NotificationManager.error(e.message);
			}
		}

		onLoad()
		return () => (isSubscribed = false);
	}, [modalOpt]);

	return (
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>{t("soajs:titles.userEdit")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={3}>{t("soajs:fields.Username")}</Form.Label>
							<Col sm={9}>
								<Form.Control
									id="username"
									size="sm"
									value={fields.username}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={3}>{t("soajs:fields.FirstName")}</Form.Label>
							<Col sm={9}>
								<Form.Control
									id="firstName"
									size="sm"
									value={fields.firstName}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={3}>{t("soajs:fields.LastName")}</Form.Label>
							<Col sm={9}>
								<Form.Control
									id="lastName"
									size="sm"
									value={fields.lastName}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={3}>{t("soajs:fields.Email")}</Form.Label>
							<Col sm={9}>
								<Form.Control
									id="email"
									size="sm"
									value={fields.email}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={3}>{t("soajs:fields.Phone")}</Form.Label>
							<Col sm={9}>
								<Form.Control
									id="phone"
									size="sm"
									value={fields.phone}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={3}>{t("soajs:fields.Status")}</Form.Label>
							<Col sm={9}>
								<Form.Control as="select" id="status"
									value={fields.status}
									onChange={handleFieldChange}
								>
									<option value="active">active</option>
									<option value="inactive">inactive</option>
									<option value="pendingNew">pendingNew</option>
									<option value="pendingJoin">pendingJoin</option>
								</Form.Control>
							</Col>
						</Form.Row>


						<hr />
						{groupsFields.map((group, index) => (
							<Form.Check key={index + 1} type="switch"
								label={group.code + " - " + group.name}
								id={group.code}
								defaultChecked={group.selected}
								onChange={(event) => {
									onChangeGroup(event.target.checked, group.code)
								}}
							/>
						))}
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="warning" onClick={handleClose}>
					{t("soajs:buttons.Cancel")}
				</Button>
				<Button variant="success" onClick={handleSubmit}
					disabled={!validateForm()}
				>
					{t("soajs:buttons.Edit")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}