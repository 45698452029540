import {AbilityBuilder} from '@casl/ability';
import {UracService} from "./index";
import {NotificationManager} from "../components";

import APIs from "../../../availableAbilities";

const uracService = UracService.getService();


async function updateAbility(ability, extKey, forceLoad) {
	const {can, rules} = new AbilityBuilder();
	let userACL = null;
	try {
		userACL = await uracService.getUserACL(extKey, forceLoad);
	} catch (e) {
		NotificationManager.error(e.message);
	}
	if (userACL && userACL.finalACL) {
		let abilityAdded = 0;
		if (APIs && APIs.abilities) {
			let services = Object.keys(APIs.abilities);
			for (let i = 0; i < services.length; i++) {
				if (userACL.finalACL[services[i]]) {
					let allAPiAvailable = false;
					if (userACL.acl[services[i]] &&
						userACL.acl[services[i]][APIs.versions[services[i]]] &&
						(!userACL.acl[services[i]][APIs.versions[services[i]]].apisPermission ||
							userACL.acl[services[i]][APIs.versions[services[i]]].apisPermission !== "restricted")) {
						allAPiAvailable = true;
					}
					let methods = Object.keys(APIs.abilities[services[i]]);
					for (let j = 0; j < methods.length; j++) {
						let apis = Object.keys(APIs.abilities[services[i]][methods[j]]);
						for (let k = 0; k < apis.length; k++) {
							let enableThisAbility = false;
							if (!allAPiAvailable) {
								if (userACL.finalACL[services[i]][methods[j]] &&
									userACL.finalACL[services[i]][methods[j]].includes(apis[k])) {
									enableThisAbility = true;
								}
							} else {
								enableThisAbility = true;
							}
							if (enableThisAbility) {
								if (Array.isArray(APIs.abilities[services[i]][methods[j]][apis[k]])) {
									for (let l = 0; l < APIs.abilities[services[i]][methods[j]][apis[k]].length; l++) {
										if (APIs.abilities[services[i]][methods[j]][apis[k]][l].I &&
											APIs.abilities[services[i]][methods[j]][apis[k]][l].A) {
											can(APIs.abilities[services[i]][methods[j]][apis[k]][l].I, APIs.abilities[services[i]][methods[j]][apis[k]][l].A);
											abilityAdded++;
										} else {
											console.log("Skipping: ", APIs.abilities[services[i]][methods[j]][apis[k]][l].M);
										}
									}
								} else {
									if (APIs.abilities[services[i]][methods[j]][apis[k]].I &&
										APIs.abilities[services[i]][methods[j]][apis[k]].A) {
										can(APIs.abilities[services[i]][methods[j]][apis[k]].I, APIs.abilities[services[i]][methods[j]][apis[k]].A);
										abilityAdded++
									} else {
										console.log("Skipping: ", APIs.abilities[services[i]][methods[j]][apis[k]].M);
									}
								}
							} else {
								if (Array.isArray(APIs.abilities[services[i]][methods[j]][apis[k]])) {
									for (let l = 0; l < APIs.abilities[services[i]][methods[j]][apis[k]].length; l++) {
										console.log("Disabled: ", APIs.abilities[services[i]][methods[j]][apis[k]][l].I, APIs.abilities[services[i]][methods[j]][apis[k]][l].A);
									}
								} else {
									console.log("Disabled: ", APIs.abilities[services[i]][methods[j]][apis[k]].I, APIs.abilities[services[i]][methods[j]][apis[k]].A);
								}
							}
						}
					}
				}
			}
		}
		console.log("Abilities Added: " + abilityAdded);
	}
	ability.update(rules);
}

const AbilitiesService = (function () {
	let _service;
	
	function _getService() {
		if (!_service) {
			_service = this;
			return _service
		}
		return _service
	}
	
	function _createUserAbilities(ability, forceLoad) {
		return new Promise((resolve) => {
			ability.on('updated', resolve);
			updateAbility(ability, null, forceLoad);
		});
	}
	
	function _updateUserAbilities(ability, extKey) {
		return new Promise((resolve) => {
			ability.on('updated', resolve);
			updateAbility(ability, extKey, false);
		});
	}
	
	return {
		getService: _getService,
		createUserAbilities: _createUserAbilities,
		updateUserAbilities: _updateUserAbilities
	}
})();
export default AbilitiesService;
