import {Accordion, Button, Card, Table, Alert, Row, Col, Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";

import {useTranslation} from "react-i18next";
import {FaKey} from "react-icons/fa";
import {GoDiffAdded} from "react-icons/go";

import DeleteButton from "../../../../buttons/Delete";
import GearButton from "../../../../buttons/Gear";
import EditButton from "../../../../buttons/Edit";
import AddButton from "../../../../buttons/Add";

import {NotificationManager} from "../../../../../soajs/urac/components";
import {ConsoleService, MultitenantService} from "../../../../../services";
import AutoPagination from "../../../../../lib/AutoPagination";
import {useAppContext} from "../../../../../soajs/libs/contextLib";

import DeleteModal from "../common/DeleteModal";
import ConfigureKeyModal from "../common/ConfigureKeyModal";
import ConfigureExtKeyModal from "../common/ConfigureExtKeyModal";
import OauthModal from "../common/OauthModal";
import AddTenantModal from "../common/AddTenantModal";
import AddApplicationModal from "../common/AddApplicationModal";
import AddExtKeyModal from "../common/AddExtKeyModal";
import UpdateTenantModal from "../common/UpdateTenantModal";
import UpdateApplicationModal from "../common/UpdateApplicationModal";
import {BiSearchAlt} from "react-icons/bi";

const multitenantService = MultitenantService.getService();
const consoleService = ConsoleService.getService();
const tenantType = "product";

function getTenantLabel(rec) {
	return (
		<div>
			<span className="font-weight-bold mr-3">{rec.name}</span><span
			className="small text-muted">[{rec.code} - {rec._id}]</span>
		</div>
	);
}

async function onLoad(setFields, setPagination, currentPage, criteria, isSubscribed) {
	try {
		let c = {"limit": 50, "skip": (currentPage - 1) * 50, "type": tenantType};
		if (criteria) {
			if (criteria.keywords && criteria.keywords !== "") {
				c.keywords = criteria.keywords;
			}
			if (criteria.category !== "All") {
				c.category = criteria.category;
			}
		}
		const response = await multitenantService.getTenants(c);
		if (isSubscribed && response && response.items) {
			setFields(response.items);
			setPagination(
				{
					"totalItems": response.count,
					"maxSize": 10,
					"itemsPerPage": response.limit
				}
			);
		}
	} catch (e) {
		NotificationManager.error(e.message);
	}
}

export default function Main() {
	const {ability} = useAppContext();
	const {t} = useTranslation(["common", "soajs"]);
	const [fields, setFields] = useState([]);
	const [environments, setEnvironments] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [pagination, setPagination] = useState({
		"totalItems": 1, "maxSize": 1, "itemsPerPage": 1
	});
	const [criteria, setCriteria] = useState({
		"keywords": "", "category": "All"
	});
	
	function handleCriteriaChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		setCriteria({
			...criteria,
			[event.target.id]: value
		});
	}
	
	async function handleSearch(event) {
		event.preventDefault();
		setCurrentPage(1);
		await reLoad(1);
	}
	
	const [modalDeleteOpt, setModalDeleteOpt] = useState({"show": false, item: null});
	const modalDeleteOptShow = (item) => setModalDeleteOpt({"show": true, item: item});
	const [modalKeyConfigurationOpt, setModalKeyConfigurationOpt] = useState({"show": false, item: null});
	const modalKeyConfigurationOptShow = (item) => setModalKeyConfigurationOpt({"show": true, item: item});
	const [modalExtKeyConfigurationOpt, setModalExtKeyConfigurationOpt] = useState({"show": false, item: null});
	const modalExtKeyConfigurationOptShow = (item) => setModalExtKeyConfigurationOpt({"show": true, item: item});
	const [modalOauthOpt, setModalOauthOpt] = useState({"show": false, item: null});
	const modalOauthOptShow = (item) => setModalOauthOpt({"show": true, item: item});
	const [modalAddTenantOpt, setModalAddTenantOpt] = useState({"show": false, item: null});
	const modalAddTenantOptShow = (item) => setModalAddTenantOpt({"show": true, item: item});
	const [modalAddApplicationOpt, setModalAddApplicationOpt] = useState({"show": false, item: null});
	const modalAddApplicationOptShow = (item) => setModalAddApplicationOpt({"show": true, item: item});
	const [modalAddExtKeyOpt, setModalAddExtKeyOpt] = useState({"show": false, item: null});
	const modalAddExtKeyOptShow = (item) => setModalAddExtKeyOpt({"show": true, item: item});
	const [modalUpdateTenantOpt, setModalUpdateTenantOpt] = useState({"show": false, item: null});
	const modalUpdateTenantOptShow = (item) => setModalUpdateTenantOpt({"show": true, item: item});
	const [modalUpdateApplicationOpt, setModalUpdateApplicationOpt] = useState({"show": false, item: null});
	const modalUpdateApplicationOptShow = (item) => setModalUpdateApplicationOpt({"show": true, item: item});
	
	async function addTenantApplicationKey(data) {
		try {
			await multitenantService.addTenantApplicationKey(data);
			await reLoad();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	async function reLoad(page) {
		await onLoad(setFields, setPagination, page || currentPage, criteria, true);
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		onLoad(setFields, setPagination, 1, null, isSubscribed).catch();
		
		async function getEnvironment() {
			try {
				const response = await consoleService.getEnvironments();
				if (response && isSubscribed) {
					setEnvironments(response);
				}
			} catch (e) {
				NotificationManager.error(e.message);
			}
		}
		
		getEnvironment().catch();
		return () => (isSubscribed = false);
	}, []);
	
	return (
		<>
			<>
				<UpdateTenantModal
					modalOpt={modalUpdateTenantOpt}
					setModalOpt={setModalUpdateTenantOpt}
					handleAction={reLoad}
				/>
				<UpdateApplicationModal
					modalOpt={modalUpdateApplicationOpt}
					setModalOpt={setModalUpdateApplicationOpt}
					handleAction={reLoad}
				/>
				<AddExtKeyModal
					modalOpt={modalAddExtKeyOpt}
					setModalOpt={setModalAddExtKeyOpt}
					handleAction={reLoad}
					environments={environments}
				/>
				<AddApplicationModal
					modalOpt={modalAddApplicationOpt}
					setModalOpt={setModalAddApplicationOpt}
					handleAction={reLoad}
				/>
				<AddTenantModal
					modalOpt={modalAddTenantOpt}
					setModalOpt={setModalAddTenantOpt}
					handleAction={reLoad}
				/>
				<OauthModal
					modalOpt={modalOauthOpt}
					setModalOpt={setModalOauthOpt}
					handleAction={reLoad}
				/>
				<ConfigureExtKeyModal
					modalOpt={modalExtKeyConfigurationOpt}
					setModalOpt={setModalExtKeyConfigurationOpt}
					handleAction={reLoad}
					environments={environments}
				/>
				<ConfigureKeyModal
					modalOpt={modalKeyConfigurationOpt}
					setModalOpt={setModalKeyConfigurationOpt}
					handleAction={reLoad}
					environments={environments}
				/>
				<DeleteModal
					modalOpt={modalDeleteOpt}
					setModalOpt={setModalDeleteOpt}
					handleAction={reLoad}
				/>
			</>
			<div className="mb-1">
				<h5 className="float-left font-weight-bold">Available main tenants</h5>
				
				{ability.can('tenant', 'add') &&
				<Button
					className="float-right mb-1"
					variant="success"
					size="sm"
					onClick={() => {
						modalAddTenantOptShow({"type": tenantType});
					}}
				><GoDiffAdded/> {t("soajs:buttons.Add")}</Button>}
				
				<span className="clearfix"></span>
				<Alert variant="secondary mt-2">
					<Form onSubmit={handleSearch}>
						<Row>
							<Col sm={5} md={6} lg={7}>
								<Form.Control
									id="keywords"
									size="sm"
									autoFocus
									value={criteria.keywords}
									onChange={handleCriteriaChange}
								/>
							</Col>
							<Col sm={4} md={4} lg={3}>
								<Form.Control
									as="select"
									id="category"
									size="sm"
									defaultValue={criteria.category}
									onChange={handleCriteriaChange}
								>
									<option value="All">All</option>
									<option value="tenant">Tenant Only</option>
									<option value="application">Application Only</option>
									<option value="integration">Integration Only</option>
								</Form.Control>
							</Col>
							<Col sm={3} md={2} lg={2}>
								<Button className="float-right" variant="dark" size="sm" onClick={handleSearch}>
									<BiSearchAlt/> {t("soajs:buttons.Search")}
								</Button>
							</Col>
						</Row>
					</Form>
				</Alert>
				<hr/>
			</div>
			{fields.map((rec, index) => (
				<Accordion className="mb-2" key={index + 1} defaultActiveKey={1}>
					<Card>
						<Card.Header style={{"backgroundColor": "#DEF1F7"}}>
							<Row>
								<Col xs={12} md={8}>
							<Accordion.Toggle className="p-0" as={Button} variant="link"
							                  eventKey={index + 1}>
								{getTenantLabel(rec)}
							</Accordion.Toggle>
								</Col>
									<Col xs={12} md={4}>
							
							{ability.can('tenant', 'delete') &&
							<DeleteButton
								className="float-right ml-1"
								onClick={() => {
									modalDeleteOptShow({
										"type": "tenant",
										"label": rec.name,
										"id": rec._id
									});
								}}
							/>}
							
							{ability.can('tenant', 'configure') &&
							<GearButton
								className="float-right ml-1"
								onClick={() => {
									modalOauthOptShow({
										"id": rec._id,
										"secret": rec.oauth.secret,
										"type": rec.oauth.type,
										"disabled": rec.oauth.disabled,
										"oauthType": rec.oauth.loginMode
									});
								}}
							/>}
							
							{ability.can('tenant', 'edit') &&
							<EditButton
								className="float-right ml-1"
								onClick={() => {
									modalUpdateTenantOptShow({
										"id": rec._id,
										"name": rec.name,
										"tag": rec.tag || "",
										"description": rec.description || "",
										"profile": rec.profile || {},
										"category": rec.category || "tenant",
									})
								}}
							/>}
							
							{ability.can('tenant', 'add') &&
							<AddButton
								className="float-right ml-1"
								onClick={() => {
									modalAddTenantOptShow({
										"type": "client",
										"mainTenant": rec._id,
										"mainTenantName": rec.name
									});
								}}
							/>}
									</Col>
							</Row>
						</Card.Header>
						<Accordion.Collapse eventKey={index + 1}>
							<Card.Body>
								<span className="small">{rec.description}</span>
								<hr className="mt-4"/>
								<div className="mb-1">
									<h6 className="float-left font-weight-bold">Available applications</h6>
									
									{ability.can('tenant_application', 'add') &&
									<Button
										className="float-right mb-1"
										variant="success"
										size="sm"
										onClick={() => {
											modalAddApplicationOptShow({
												"id": rec._id
											});
										}}
									><GoDiffAdded/> {t("soajs:buttons.Add")}</Button>}
									
									<span className="clearfix"></span>
								</div>
								{rec.applications && rec.applications.map((appRec, index) => (
									<Accordion className="mb-2" key={index + 1} defaultActiveKey={1}>
										<Card>
											<Card.Header>
												<Row>
													<Col xs={12} md={8}>
												<Accordion.Toggle className="p-0" as={Button} variant="link"
												                  eventKey={index + 1}>
													<span
														className="font-weight-bold mr-3 small">{appRec.package}</span><span
													className="small text-muted">[{appRec.appId}]</span>
													<span className="text-success small ml-3">{[appRec.product]}</span>
												</Accordion.Toggle>
													</Col>
													<Col xs={12} md={4}>
												{ability.can('tenant_application', 'delete') &&
												<DeleteButton
													className="float-right ml-1"
													onClick={() => {
														modalDeleteOptShow({
															"type": "application",
															"label": appRec.package,
															"id": rec._id,
															"appId": appRec.appId
														});
													}}
												/>}
												
												{ability.can('tenant_application', 'edit') &&
												<EditButton
													className="float-right ml-1"
													onClick={() => {
														modalUpdateApplicationOptShow({
															"id": rec._id,
															"appId": appRec.appId,
															"description": appRec.description || "",
															"_TTL": appRec._TTL ? appRec._TTL / 3600000 : 168,
															"package": appRec.package
														})
													}}
												/>}
													</Col>
												</Row>
											</Card.Header>
											<Accordion.Collapse eventKey={index + 1}>
												<Card.Body>
													<span className="small">{appRec.description}</span>
													<hr className="mt-4"/>
													<div className="mb-1">
														<h6 className="float-left font-weight-bold">Available internal
															keys
														</h6>
														
														{ability.can('tenant_application_key', 'add') &&
														<Button
															className="float-right mb-1"
															variant="success"
															size="sm"
															onClick={() => {
																addTenantApplicationKey({
																	"id": rec._id,
																	"appId": appRec.appId
																});
															}}
														>
															<GoDiffAdded/> {t("soajs:buttons.Add")}
														</Button>}
														
														<span className="clearfix"></span>
													</div>
													{appRec.keys && appRec.keys.map((keyRec, index) => (
														<Accordion className="mb-2" key={index + 1}
														           defaultActiveKey={1}>
															<Card>
																<Card.Header className="p-1 pl-2 pr-2"
																             style={{"backgroundColor": "#dee2e6"}}>
																	<Row>
																		<Col xs={12} md={8}>
																	<Accordion.Toggle className="p-0" as={Button}
																	                  variant="link"
																	                  eventKey={index + 1}>
																		<span
																			className="font-weight-bold mr-3 small"><FaKey
																			className="mr-2"/>{keyRec.key}</span>
																	</Accordion.Toggle>
																		</Col>
																		<Col xs={12} md={4}>
																	{ability.can('tenant_application_key', 'delete') &&
																	<DeleteButton
																		className="float-right ml-2"
																		onClick={() => {
																			modalDeleteOptShow({
																				"type": "key",
																				"label": keyRec.key,
																				"id": rec._id,
																				"appId": appRec.appId,
																				"key": keyRec.key
																			});
																		}}
																	/>}
																	
																	{ability.can('tenant_application_key', 'configure') &&
																	<GearButton
																		className="float-right ml-2"
																		onClick={() => {
																			modalKeyConfigurationOptShow({
																				"id": rec._id,
																				"appId": appRec.appId,
																				"key": keyRec.key,
																				"configAllEnv": keyRec.config
																			});
																		}}
																	/>}
																		</Col>
																	</Row>
																</Card.Header>
																<Accordion.Collapse eventKey={index + 1}>
																	<Card.Body>
																		<h6 className="float-left font-weight-bold">Available
																			external keys
																		</h6>
																		
																		{ability.can('tenant_application_ekey', 'add') &&
																		<Button
																			className="float-right mb-1"
																			variant="success"
																			size="sm"
																			onClick={() => {
																				modalAddExtKeyOptShow({
																					"id": rec._id,
																					"appId": appRec.appId,
																					"key": keyRec.key
																				});
																			}}
																		><GoDiffAdded/> {t("soajs:buttons.Add")}
																		</Button>}
																		
																		<Table responsive striped hover size="sm"
																		       className="small">
																			<thead className="text-light bg-dark">
																			<tr>
																				<th>{t("soajs:buttons.Actions")}</th>
																				<th>{t("soajs:fields.ExternalKey")}</th>
																				<th className="text-center">{t("soajs:fields.Environments")}</th>
																				<th className="text-right">{t("soajs:fields.ExpDate")}</th>
																			</tr>
																			</thead>
																			<tbody>
																			{keyRec.extKeys && keyRec.extKeys.map((extKeyRec, index) => (
																				<tr key={index}>
																					<td>
																						{ability.can('tenant_application_ekey', 'delete') &&
																						<DeleteButton
																							className="mr-2"
																							onClick={() => {
																								modalDeleteOptShow({
																									"type": "extKey",
																									"label": extKeyRec.extKey,
																									"id": rec._id,
																									"appId": appRec.appId,
																									"key": keyRec.key,
																									"extKey": extKeyRec.extKey
																								});
																							}}
																						/>}
																						
																						{ability.can('tenant_application_ekey', 'configure') &&
																						<GearButton
																							onClick={() => {
																								modalExtKeyConfigurationOptShow({
																									"id": rec._id,
																									"appId": appRec.appId,
																									"key": keyRec.key,
																									"extKey": extKeyRec.extKey,
																									"expDate": extKeyRec.expDate || null,
																									"device": extKeyRec.device || {},
																									"geo": extKeyRec.geo || {},
																									"label": extKeyRec.label || "",
																									"extKeyEnv": extKeyRec.env || ""
																								});
																							}}
																						/>}
																					
																					</td>
																					<td style={{
																						"wordBreak": "break-all",
																						"maxWidth": "400px"
																					}}>{extKeyRec.extKey}</td>
																					<td className="text-center">{extKeyRec.env}</td>
																					<td className="text-right">{extKeyRec.expDate ? new Date(extKeyRec.expDate).toISOString() : null}</td>
																				</tr>
																			))}
																			</tbody>
																		</Table>
																	</Card.Body>
																</Accordion.Collapse>
															</Card>
														</Accordion>
													))}
												</Card.Body>
											</Accordion.Collapse>
										</Card>
									</Accordion>
								))}
							</Card.Body>
						</Accordion.Collapse>
					</Card>
				</Accordion>
			))}
			<div className="float-right mt-3">
				<AutoPagination
					currentPage={currentPage}
					totalItems={pagination.totalItems}
					itemsPerPage={pagination.itemsPerPage}
					maxSize={pagination.maxSize}
					onClick={(p) => {
						setCurrentPage(p);
						reLoad(p).catch();
					}}
				/>
			</div>
			<div className="clearfix"></div>
		</>
	);
}