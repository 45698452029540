import axios from "axios";
import LocalStorageService from "./LocalStorageService.js";

import Config from "../../../config.js";

const localStorageService = LocalStorageService.getService();

const UracService = (function () {
	let _service = null;

	function _getService() {
		if (!_service) {
			_service = this;
			return _service
		}
		return _service
	}

	function _updateMyProfile(fields, profile) {
		const userid = localStorageService.getUserid();
		if (userid) {
			let record = {
				"id": userid,
				"firstName": fields.firstName,
				"lastName": fields.lastName
			};
			if (profile) {
				record.profile = profile;
			}
			return axios.put("/urac/account", record)
				.then(res => {
					if (res && res.data && res.data.result) {
						localStorageService.setFirstrname(fields.firstName);
						return Promise.resolve("Profile has been updated.");
					} else {
						return Promise.reject(new Error("Unable to update profile information"));
					}
				})
				.catch((error) => {
					if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.codes && error.response.data.errors.codes.includes(533)) {
						return Promise.resolve("Nothing to update!");
					} else {
						return Promise.reject(new Error("Unable to update profile information"));
					}
				});
		} else {
			return Promise.reject(new Error("Unable to update profile information"));
		}
	}

	function _changeEmail(email) {
		const userid = localStorageService.getUserid();
		if (userid) {
			return axios.put("/urac/account/email",
				{
					"id": userid,
					"email": email
				})
				.then(res => {
					if (res && res.data && res.data.result) {
						return Promise.resolve(res.data.data);
					} else {
						return Promise.reject(new Error("Unable to change email"));
					}
				})
				.catch(() => {
					return Promise.reject(new Error("Unable to change email"));
				});
		} else {
			return Promise.reject(new Error("Unable to change email"));
		}
	}

	function _changePassword(password, newPassword, confirmNewPassword) {
		const userid = localStorageService.getUserid();
		if (userid) {
			return axios.put("/urac/account/password",
				{
					"id": userid,
					"oldPassword": password,
					"password": newPassword,
					"confirmation": confirmNewPassword
				})
				.then(res => {
					if (res && res.data && res.data.result) {
						return Promise.resolve(res.data.data);
					} else {
						return Promise.reject(new Error("Unable to change password"));
					}
				})
				.catch(() => {
					return Promise.reject(new Error("Unable to change password"));
				});
		} else {
			return Promise.reject(new Error("Unable to change password"));
		}
	}

	function _forgotPassword(username) {
		return axios.get("/urac/password/forgot/?username=" + username)
			.then(res => {
				if (res && res.data && res.data.result) {
					return Promise.resolve(res.data.data);
				} else {
					return Promise.reject(new Error("Unable to proceed please try again later"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to proceed please try again later"));
			});
	}

	function _signup(fields) {
		return axios.post("/urac/join",
			{
				"username": fields.username,
				"firstName": fields.firstName,
				"lastName": fields.lastName,
				"email": fields.email,
				"password": fields.password
			})
			.then(res => {
				if (res && res.data && res.data.result) {
					return Promise.resolve(res.data.data);
				} else {
					return Promise.reject(new Error("Unable to sign up"));
				}
			})
			.catch((error) => {
				if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.codes && error.response.data.errors.codes.includes(402)) {
					return Promise.reject(new Error("Username and/or Email already exist."));
				} else {
					return Promise.reject(new Error("Unable to sign up"));
				}
			});
	}

	function _getUser() {
		const user = localStorageService.getUsername();
		if (user) {
			return axios.get("/urac/user?username=" + user)
				.then(res => {
					if (res && res.data && res.data.result) {
						localStorageService.setFirstrname(res.data.data.firstName);
						if (res.data.data.profile && res.data.data.profile.gravatar) {

							localStorageService.setEmail(res.data.data.profile.gravatar);
						} else {
							localStorageService.setEmail(res.data.data.email);
						}
						localStorageService.setUserid(res.data.data._id);
						return Promise.resolve(res.data.data);
					} else {
						return Promise.reject(new Error("Unable to get user information"));
					}
				})
				.catch(() => {
					return Promise.reject(new Error("Unable to get user information"));
				});
		} else {
			return Promise.reject(new Error("Unable to get user information"));
		}
	}

	function _validateJoin(token) {
		return axios.get("/urac/validate/join?token=" + token)
			.then(res => {
				if (res && res.data && res.data.result) {
					return Promise.resolve(true);
				} else {
					return Promise.reject(new Error("Unable to proceed please try again later"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to proceed please try again later"));
			});
	}

	function _emailToken(username) {
		return axios.get("/urac/emailToken?username=" + username)
			.then(res => {
				if (res && res.data && res.data.result) {
					return Promise.resolve(true);
				} else {
					return Promise.reject(new Error("Unable to proceed please try again later"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to proceed please try again later"));
			});
	}

	function _resetPassword(token, newPassword, confirmNewPassword) {
		return axios.put("/urac/password/reset",
			{
				"token": token,
				"confirmation": confirmNewPassword,
				"password": newPassword
			})
			.then(res => {
				if (res && res.data && res.data.result) {
					return Promise.resolve(true);
				} else {
					return Promise.reject(new Error("Unable to reset password"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to reset password"));
			});
	}

	function _validateChangeEmail(token) {
		return axios.get("/urac/validate/changeEmail?token=" + token)
			.then(res => {
				if (res && res.data && res.data.result) {
					return Promise.resolve(true);
				} else {
					return Promise.reject(new Error("Unable to proceed please try again later"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to proceed please try again later"));
			});
	}

	function _getUserId(id, extKey) {
		let opt = null
		if (extKey) {
			opt = {
				"headers": { "key": extKey }
			}
		}
		return axios.get("/urac/admin/user?id=" + id, opt)
			.then(res => {
				if (res && res.data && res.data) {
					return Promise.resolve(res.data.data);
				} else {
					return Promise.reject(new Error("Unable to get user information"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to get user information"));
			});
	}

	function _getUsersId(ids, extKey) {
		let opt = null
		if (extKey) {
			opt = {
				"headers": { "key": extKey }
			}
		}
		return axios.post("/urac/admin/users/ids", { "ids": ids }, opt)
			.then(res => {
				if (res && res.data && res.data) {
					return Promise.resolve(res.data.data);
				} else {
					return Promise.reject(new Error("Unable to get users"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to get users"));
			});
	}

	function _getUserACL(extKey, forceLoad) {
		//TODO: check cache
		let cacheIndex = "0000000000000000";
		if (extKey) {
			cacheIndex = extKey.substr(extKey.length - 16);
		}
		if (!forceLoad) {
			let acl_cached = localStorageService.getUserKeyACL(cacheIndex);
			if (acl_cached) {
				return Promise.resolve(acl_cached);
			}
		}
		let opt = null
		if (extKey) {
			opt = {
				"headers": { "key": extKey }
			}
		}
		return axios.get("/soajs/acl", opt)
			.then(res => {
				if (res && res.data) {
					//TODO: cache
					localStorageService.setUserKeyACL(res.data, cacheIndex);
					return Promise.resolve(res.data);
				} else {
					return Promise.reject(new Error("Unable to get ACL please try again later"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to get ACL please try again later"));
			});
	}

	function _getUsers(extKey, scope, criteria) {
		let opt = {
			"params": {
				"config": true
			}
		};
		if (extKey) {
			opt.headers = { "key": extKey };
		}
		let url = "/urac/admin/users";
		if (scope) {
			opt.params.scope = scope;
		}
		if (criteria) {
			if (criteria.keywords) {
				opt.params.keywords = criteria.keywords;
			}
			if (criteria.limit) {
				opt.params.limit = criteria.limit;
			}
			if (criteria.start) {
				opt.params.start = criteria.start;
			}
		}
		return axios.get(url, opt)
			.then(res => {
				if (res && res.data && res.data) {
					return Promise.resolve(res.data.data);
				} else {
					return Promise.reject(new Error("Unable to proceed please try again later"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to proceed please try again later"));
			});
	}

	function _getUsersCount(extKey, scope, criteria) {
		let opt = {
			"params": {}
		};
		if (extKey) {
			opt.headers = { "key": extKey };
		}
		let url = "/urac/admin/users/count";
		if (scope) {
			opt.params.scope = scope;
		}
		if (criteria) {
			if (criteria.keywords) {
				opt.params.keywords = criteria.keywords;
			}
		}
		return axios.get(url, opt)
			.then(res => {
				if (res && res.data && res.data) {
					return Promise.resolve(res.data.data);
				} else {
					return Promise.reject(new Error("Unable to proceed please try again later"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to proceed please try again later"));
			});
	}

	function _getGroups(extKey) {
		let opt = null
		if (extKey) {
			opt = {
				"headers": { "key": extKey }
			}
		}
		return axios.get("/urac/admin/groups", opt)
			.then(res => {
				if (res && res.data && res.data) {
					return Promise.resolve(res.data.data);
				} else {
					return Promise.reject(new Error("Unable to proceed please try again later"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to proceed please try again later"));
			});
	}

	function _getGroup(id, extKey) {
		let opt = null
		if (extKey) {
			opt = {
				"headers": { "key": extKey }
			}
		}
		return axios.get("/urac/admin/group?id=" + id, opt)
			.then(res => {
				if (res && res.data && res.data) {
					return Promise.resolve(res.data.data);
				} else {
					return Promise.reject(new Error("Unable to proceed please try again later"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to proceed please try again later"));
			});
	}

	function _deleteGroup(id, extKey) {
		let opt = null
		if (extKey) {
			opt = {
				"headers": { "key": extKey }
			}
		}
		return axios.delete("/urac/admin/group?id=" + id, opt)
			.then(res => {
				if (res && res.data && res.data.result) {
					return Promise.resolve("Group has been deleted.");
				} else {
					return Promise.reject(new Error("Unable to delete group"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to delete group"));
			});
	}

	function _deleteUser(id, extKey) {
		let opt = null
		if (extKey) {
			opt = {
				"headers": { "key": extKey }
			}
		}
		return axios.delete("/urac/admin/user?id=" + id, opt)
			.then(res => {
				if (res && res.data && res.data.result) {
					return Promise.resolve("User has been deleted.");
				} else {
					return Promise.reject(new Error("Unable to delete user"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to delete user"));
			});
	}

	function _addUser(user, extKey) {
		let opt = null
		if (extKey) {
			opt = {
				"headers": { "key": extKey }
			}
		}
		let data = {
			"email": user.email,
			"username": user.username,
			"firstName": user.firstName,
			"lastName": user.lastName
		};
		if (user.groups) {
			data.groups = user.groups;
		}
		return axios.post("/urac/admin/user", data, opt)
			.then(res => {
				if (res && res.data && res.data.result) {
					return Promise.resolve(res.data.data);
				} else {
					return Promise.reject(new Error("Unable to add user"));
				}
			})
			.catch((error) => {
				if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.codes && error.response.data.errors.codes.includes(402)) {
					return Promise.reject(new Error("Username and/or Email already exist."));
				} else {
					return Promise.reject(new Error("Unable to add user"));
				}
			});
	}

	function _checkIfUserExist(user, extKey) {
		let opt = null
		if (extKey) {
			opt = {
				"headers": { "key": extKey }
			}
		}
		return axios.get("/urac/checkUsername?username=" + user, opt)
			.then(res => {
				if (res && res.data && res.data.result) {
					return Promise.resolve(res.data.data);
				} else {
					return Promise.reject(new Error("Unable to check if user exists"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to check if user exists"));
			});
	}

	function _updateUser(user, extKey) {
		let opt = null
		if (extKey) {
			opt = {
				"headers": { "key": extKey }
			}
		}
		let data = {
			"id": user.id,
			"username": user.username,
			"firstName": user.firstName,
			"lastName": user.lastName,
			"email": user.email,
			"status": user.status,
			"groups": user.groups
		};
		return axios.put("/urac/admin/user", data, opt)
			.then(res => {
				if (res && res.data && res.data.result) {
					return Promise.resolve("User has been updated.");
				} else {
					return Promise.reject(new Error("Unable to updated user"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to updated user"));
			});
	}

	function _editUser(user, extKey) {
		let opt = null
		if (extKey) {
			opt = {
				"headers": { "key": extKey }
			}
		}
		let data = {
			"user": { "id": user.id },
			"groups": user.groups
		};
		return axios.put("/urac/admin/user/groups", data, opt)
			.then(res => {
				if (res && res.data && res.data.result) {
					return Promise.resolve("User has been updated.");
				} else {
					return Promise.reject(new Error("Unable to updated user"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to updated user"));
			});
	}

	function _addGroup(group, extKey) {
		let opt = null
		if (extKey) {
			opt = {
				"headers": { "key": extKey }
			}
		}
		let data = {
			"code": group.code,
			"name": group.name,
			"description": group.description,
			"packages": group.packages
		};
		return axios.post("/urac/admin/group", data, opt)
			.then(res => {
				if (res && res.data && res.data.result) {
					return Promise.resolve("Group has been added.");
				} else {
					return Promise.reject(new Error("Unable to added group"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to added group"));
			});
	}

	function _editGroup(group, extKey) {
		let opt = null
		if (extKey) {
			opt = {
				"headers": { "key": extKey },
			};
		}
		let data = {
			"id": group.id,
			"name": group.name,
			"description": group.description,
			"packages": group.packages
		};
		return axios.put("/urac/admin/group", data, opt)
			.then(res => {
				if (res && res.data && res.data.result) {
					return Promise.resolve("Group has been updated.");
				} else {
					return Promise.reject(new Error("Unable to updated group"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to updated group"));
			});
	}


	function _uninviteUserProxy(user, extKey, tenant, env) {
		let opt = {
			"params": {
				"proxyRoute": "/urac/admin/users/uninvite/tenant",
				"extKey": extKey
			},
			"headers": {
				"__env": env.toLowerCase()
			}
		};
		let data = [{
			"user": {
				"id": user.id
			}
		}];
		let url = "/soajs/proxy";
		return axios.put(url, { "users": data, "tenant": tenant }, opt)
			.then(res => {
				if (res && res.data && res.data.result) {
					if (res.data.data && res.data.data.failed && Array.isArray(res.data.data.failed) && res.data.data.failed.length > 0) {
						if (res.data.data.failed[0].reason) {
							return Promise.reject(new Error(res.data.data.failed[0].reason));
						}
					}
					return Promise.resolve("User has been un-invited.");
				} else {
					return Promise.reject(new Error("Unable to un-invite user"));
				}
			})
			.catch((e) => {
				if (e.message) {
					return Promise.reject(e);
				} else {
					return Promise.reject(new Error("Unable to un-invite user"));
				}
			});
	}
	function _inviteUserProxy(user, extKey, tenant, env) {
		let opt = {
			"params": {
				"proxyRoute": "/urac/admin/users/invite/tenant",
				"extKey": extKey
			},
			"headers": {
				"__env": env.toLowerCase()
			}
		};
		let data = [{
			"user": {},
			"groups": user.groups || null
		}];
		if (user.user.indexOf("@") !== -1) {
			data[0].user.email = user.user;
		} else {
			data[0].user.username = user.user;
		}
		let url = "/soajs/proxy";
		return axios.put(url, { "users": data, "tenant": tenant }, opt)
			.then(res => {
				if (res && res.data && res.data.result) {
					if (res.data.data && res.data.data.failed && Array.isArray(res.data.data.failed) && res.data.data.failed.length > 0) {
						if (res.data.data.failed[0].reason) {
							if (Config.alreadyInvited.includes(res.data.data.failed[0].reason)) {
								return Promise.resolve(res.data.data.failed[0]);
							} else {
								return Promise.reject(new Error(res.data.data.failed[0].reason));
							}
						}
					}
					return Promise.resolve("User has been invited.");
				} else {
					return Promise.reject(new Error("Unable to invite user"));
				}
			})
			.catch((e) => {
				if (e.message) {
					return Promise.reject(e);
				} else {
					return Promise.reject(new Error("Unable to invite user"));
				}
			});
	}
	function _addUserTenantProxy(user, extKey, tenant, env) {
		let opt = {
			"params": {
				"proxyRoute": "/urac/admin/user/tenant",
				"extKey": extKey
			},
			"headers": {
				"__env": env.toLowerCase()
			}
		};
		let data = {
			"username": user.username,
			"firstName": user.firstName,
			"lastName": user.lastName,
			"email": user.email,
			"phone": user.phone || null,
			"status": user.status,
			"tenant": tenant,
			"password": user.password
		};
		if (user.groups) {
			data.groups = user.groups;
		}
		let url = "/soajs/proxy";
		return axios.post(url, data, opt)
			.then(res => {
				if (res && res.data && res.data.result) {
					return Promise.resolve(res.data.data);
				} else {
					return Promise.reject(new Error("Unable to add user"));
				}
			})
			.catch((error) => {
				if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.codes && error.response.data.errors.codes.includes(402)) {
					return Promise.reject(new Error("Username and/or Email already exist."));
				} else {
					return Promise.reject(new Error("Unable to add user"));
				}
			});
	}
	function _deleteUserProxy(id, extKey, env) {
		let opt = {
			"params": {
				"proxyRoute": "/urac/admin/user",
				"extKey": extKey
			},
			"headers": {
				"__env": env.toLowerCase()
			}
		};
		let url = "/soajs/proxy";
		return axios.delete(url + "?id=" + id, opt)
			.then(res => {
				if (res && res.data && res.data.result) {
					return Promise.resolve("User has been deleted.");
				} else {
					return Promise.reject(new Error("Unable to delete user"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to delete user"));
			});
	}
	function _editUseProxy(user, extKey, env) {
		let opt = {
			"params": {
				"proxyRoute": "/urac/admin/user",
				"extKey": extKey
			},
			"headers": {
				"__env": env.toLowerCase()
			}
		};
		let data = {
			"id": user.id,
			"username": user.username,
			"firstName": user.firstName,
			"lastName": user.lastName,
			"email": user.email,
			"phone": user.phone || null,
			"status": user.status
		};
		if (user.groups) {
			data.groups = user.groups;
		}
		let url = "/soajs/proxy";
		return axios.put(url, data, opt)
			.then(res => {
				if (res && res.data && res.data.result) {
					return Promise.resolve("User has been updated.");
				} else {
					return Promise.reject(new Error("Unable to edit user"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to edit user"));
			});
	}
	function _changeUserPasswordProxy(user, extKey, env) {
		let opt = {
			"params": {
				"proxyRoute": "/urac/admin/account/password",
				"extKey": extKey
			},
			"headers": {
				"__env": env.toLowerCase()
			}
		};
		let data = {
			"id": user.id,
			"password": user.password
		};
		let url = "/soajs/proxy";
		return axios.put(url, data, opt)
			.then(res => {
				if (res && res.data && res.data.result) {
					return Promise.resolve("Password has been updated.");
				} else {
					return Promise.reject(new Error("Unable to change password"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to change password"));
			});
	}
	function _deleteGroupProxy(id, extKey, env) {
		let opt = {
			"params": {
				"proxyRoute": "/urac/admin/group",
				"extKey": extKey
			},
			"headers": {
				"__env": env.toLowerCase()
			}
		};
		let url = "/soajs/proxy";
		return axios.delete(url + "?id=" + id, opt)
			.then(res => {
				if (res && res.data && res.data.result) {
					return Promise.resolve("Group has been deleted.");
				} else {
					return Promise.reject(new Error("Unable to delete group"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to delete group"));
			});
	}

	function _getUsersProxy_v2(extKey, tenantId, scope, criteria, env) {
		let opt = {
			"params": {
				"proxyRoute": "/urac/api-v2/admin/users",
				"config": true,
				"extKey": extKey,
				"tenant": { "id": tenantId, "code": "NNNNN" },
			},
			"headers": {
				"__env": env.toLowerCase()
			}
		};
		let url = "/soajs/proxy";
		if (scope) {
			opt.params.scope = scope;
		}
		if (criteria) {
			if (criteria.keywords) {
				opt.params.keywords = criteria.keywords;
			}
			if (criteria.limit) {
				opt.params.limit = criteria.limit;
			}
			if (criteria.start) {
				opt.params.start = criteria.start;
			}
		}
		return axios.get(url, opt)
			.then(res => {
				if (res && res.data && res.data) {
					return Promise.resolve(res.data.data);
				} else {
					return Promise.reject(new Error("Unable to proceed please try again later"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to proceed please try again later"));
			});
	}
	function _getGroupsProxy_v2(extKey, tenantId, env) {
		let opt = {
			"params": {
				"proxyRoute": "/urac/admin/groups/tenant",
				"extKey": extKey,
				"tenantId": tenantId
			},
			"headers": {
				"__env": env.toLowerCase()
			}
		};
		let url = "/soajs/proxy";
		return axios.get(url, opt)
			.then(res => {
				if (res && res.data && res.data) {
					return Promise.resolve(res.data.data);
				} else {
					return Promise.reject(new Error("Unable to proceed please try again later"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to proceed please try again later"));
			});
	}
	function _getUsersProxy(extKey, scope, criteria, env) {
		let opt = {
			"params": {
				"proxyRoute": "/urac/admin/users",
				"config": true,
				"extKey": extKey
			},
			"headers": {
				"__env": env.toLowerCase()
			}
		};
		let url = "/soajs/proxy";
		if (scope) {
			opt.params.scope = scope;
		}
		if (criteria) {
			if (criteria.keywords) {
				opt.params.keywords = criteria.keywords;
			}
			if (criteria.limit) {
				opt.params.limit = criteria.limit;
			}
			if (criteria.start) {
				opt.params.start = criteria.start;
			}
		}
		return axios.get(url, opt)
			.then(res => {
				if (res && res.data && res.data) {
					return Promise.resolve(res.data.data);
				} else {
					return Promise.reject(new Error("Unable to proceed please try again later"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to proceed please try again later"));
			});
	}
	function _getUsersCountProxy(extKey, scope, criteria, env) {
		let opt = {
			"params": {
				"proxyRoute": "/urac/admin/users/count",
				"config": true,
				"extKey": extKey
			},
			"headers": {
				"__env": env.toLowerCase()
			}
		};
		let url = "/soajs/proxy";
		if (scope) {
			opt.params.scope = scope;
		}
		if (criteria) {
			if (criteria.keywords) {
				opt.params.keywords = criteria.keywords;
			}
		}
		return axios.get(url, opt)
			.then(res => {
				if (res && res.data && res.data) {
					return Promise.resolve(res.data.data);
				} else {
					return Promise.reject(new Error("Unable to proceed please try again later"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to proceed please try again later"));
			});
	}
	function _getGroupsProxy(extKey, env) {
		let opt = {
			"params": {
				"proxyRoute": "/urac/admin/groups",
				"extKey": extKey
			},
			"headers": {
				"__env": env.toLowerCase()
			}
		};
		let url = "/soajs/proxy";
		return axios.get(url, opt)
			.then(res => {
				if (res && res.data && res.data) {
					return Promise.resolve(res.data.data);
				} else {
					return Promise.reject(new Error("Unable to proceed please try again later"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to proceed please try again later"));
			});
	}


	return {
		getService: _getService,
		changePassword: _changePassword,
		forgotPassword: _forgotPassword,
		changeEmail: _changeEmail,
		signup: _signup,
		updateMyProfile: _updateMyProfile,
		getUser: _getUser,
		getUserId: _getUserId,
		getUsersId: _getUsersId,
		validateJoin: _validateJoin,
		emailToken: _emailToken,
		resetPassword: _resetPassword,
		validateChangeEmail: _validateChangeEmail,
		getUserACL: _getUserACL,
		getUsers: _getUsers,
		getUsersCount: _getUsersCount,
		getGroup: _getGroup,
		getGroups: _getGroups,
		deleteGroup: _deleteGroup,
		deleteUser: _deleteUser,
		addUser: _addUser,
		editUser: _editUser,
		updateUser: _updateUser,
		editGroup: _editGroup,
		addGroup: _addGroup,
		checkIfUserExist: _checkIfUserExist,

		uninviteUserProxy: _uninviteUserProxy,
		inviteUserProxy: _inviteUserProxy,
		addUserTenantProxy: _addUserTenantProxy,
		deleteUserProxy: _deleteUserProxy,
		editUseProxy: _editUseProxy,
		changeUserPasswordProxy: _changeUserPasswordProxy,

		deleteGroupProxy: _deleteGroupProxy,

		getUsersProxy: _getUsersProxy,
		getGroupsProxy: _getGroupsProxy,
		getUsersCountProxy: _getUsersCountProxy,
		getUsersProxy_v2: _getUsersProxy_v2,
		getGroupsProxy_v2: _getGroupsProxy_v2
	}
})();
export default UracService;
